import { Component, Output, EventEmitter, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../services/form-field-control.service';
import { FormField } from '../../../shared/form-field/form-field';
import { ServiceBenchModalService } from './service-bench-modal.service';
import { EmailService } from '../../../services/email.service';
import { MessageService } from 'primeng/api';
import { ChangeDetectorRef } from '@angular/core';
import { ServiceBenchTableService } from './service-bench-table/service-bench-table.service';
import { ServiceOrderDataService } from '../service-order-data.service';
import { Subscription } from 'rxjs';
import { copy, getValueByKey } from '../../../shared/utilities/common-utilities';
import { ServiceOrderService } from '../service-order.service';
import { NoteType } from '../../../shared/constants/enums';
import { AzureLoginService } from 'src/app/services/azure-login.service';

@Component({
  selector: 'service-bench-modal',
  templateUrl: 'service-bench-modal.template.html',
  styleUrls: ['./service-bench-modal.scss'],
  providers: [
    FormFieldControlService,
    ServiceBenchModalService,
    EmailService,
    MessageService,
    ServiceBenchTableService,
  ],
})
export class ServiceBenchModalComponent implements OnInit, OnDestroy {
  private privateDisplayDialog: boolean;
  @Input() modalTitle: string;
  @Input() set displayDialog(value: boolean) {
    this.privateDisplayDialog = value;
    this.displayDialogChange.emit(this.displayDialog);
  }
  get displayDialog(): boolean {
    return this.privateDisplayDialog;
  }
  @Input() isReschedule = false;
  @Output() formSubmitEvent = new EventEmitter<any>();
  @Output() formValueChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() displayDialogChange = new EventEmitter();

  formFields: FormField<string>[] = [];
  form: FormGroup;

  formFields1: FormField<string>[] = [];
  formFields2: FormField<string>[] = [];
  displaySeviceBenchModal: true;
  isLoading = false;
  isDisabled = false;
  displayInfoModal: true;
  querySubscription: Subscription;
  querySubscriptions: Subscription[] = [];
  isClaimDataSync = true;

  constructor(
    private qcs: FormFieldControlService,
    private serviceBenchModalService: ServiceBenchModalService,
    private emailService: EmailService,
    private messageService: MessageService,
    private chRef: ChangeDetectorRef,
    private serviceOrderDataService: ServiceOrderDataService,
    private sbTableService: ServiceBenchTableService,
    private serviceOrderService: ServiceOrderService,
    private azureService: AzureLoginService,
  ) {
    this.serviceBenchModalService.getSendEmailFields().subscribe((data) => {
      this.formFields = data;
    });
  }
  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formFields);
    this.formFields1 = this.formFields.slice(0, 6);
    this.formFields2 = this.formFields.slice(11, 30);
    this.form.valueChanges.subscribe((x) => {
      this.formValueChanged.emit(true);
    });
  }
  onSubmit() {
    if (this.sbTableService.selectedValue.length === 0) {
      window.alert('No Timeslot Selected');
      return;
    }
    const { lastUpdateTime, claimIdOriginal } =
      this.serviceOrderDataService.serviceOrderDataModel.claimData;
    this.isLoading = true;
    this.isDisabled = true;
    this.querySubscriptions.push(
      this.serviceOrderDataService
        .getClaimLastUpdatedTime(parseInt(claimIdOriginal, 10), this.azureService.accountId)
        .subscribe(
          ({ data, loading }: any) => {
            const claimLastUpdateTimeDb =
              data.getClaimProfileById && data.getClaimProfileById.lastUpdateTime;
            if (
              new Date(claimLastUpdateTimeDb).toISOString() ===
              new Date(lastUpdateTime).toISOString()
            ) {
              const { serviceBenchCommon } = this.serviceOrderDataService.serviceBenchModal;
              this.querySubscriptions.push(
                this.sbTableService.scheduleJob(this.sbTableService.selectedValue).subscribe(
                  (dataRes) => {
                    // console.log('Job Schedule Response: ' + JSON.stringify(data));
                    this.isLoading = false;
                    this.isDisabled = false;
                    const response = copy(dataRes);
                    const { bookingAppointment, jobResponse } = response;
                    this.serviceOrderDataService.serviceBenchModal.scheduleJobDetails =
                      bookingAppointment;
                    if (jobResponse && jobResponse.msgStatus === 'ERROR') {
                      if (
                        jobResponse.statusDetails.statusDetail.errorNo ===
                        getValueByKey(serviceBenchCommon, 'duplicateSbjErrorCode')
                      ) {
                        this.serviceOrderDataService.isServiceBenchError.next({
                          errorNo: jobResponse.statusDetails.statusDetail.errorNo,
                          errorText: jobResponse.statusDetails.statusDetail.errorText,
                        });
                        this.displayDialog = false;
                      } else {
                        this.isLoading = false;
                        this.messageService.add({
                          severity: 'error',
                          summary: 'Error',
                          detail: `ERROR: ${jobResponse.statusDetails.statusDetail.errorText}.`,
                        });
                      }
                    } else {
                      this.isLoading = false;
                      this.formSubmitEvent.emit(true);
                      this.displayDialog = false;
                      this.serviceOrderDataService.serviceOrderDataModel.isThirdPartyDispatch = true;
                    }
                  },
                  (err) => {
                    this.isLoading = false;
                    this.isDisabled = false;
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: `ERROR: There was an error scheduling the job.`,
                    });
                  },
                ),
              );
            } else {
              this.serviceOrderDataService.isLastUpdateTimeError.next(true);
              this.displayDialog = false;
              return false;
            }
          },
          (err) => {
            this.isLoading = false;
            this.isDisabled = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `ERROR: There was an error while getting last update time.`,
            });
          },
        ),
    );
  }

  onCancel() {
    this.serviceOrderDataService.serviceOrderDataModel.servicerInfo = null;
    this.displayDialog = false;
    this.displayDialogChange.emit(this.displayDialog);
  }

  onServiceBench() {
    this.displaySeviceBenchModal = true;
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  onReschedule() {
    // TODO
    if (this.sbTableService.selectedValue.length === 0) {
      window.alert('No Timeslot Selected');
      return;
    }
    const { lastUpdateTime, claimIdOriginal } =
      this.serviceOrderDataService.serviceOrderDataModel.claimData;
    this.isLoading = true;
    this.isDisabled = true;
    this.querySubscriptions.push(
      this.serviceOrderDataService
        .getClaimLastUpdatedTime(parseInt(claimIdOriginal, 10), this.azureService.accountId)
        .subscribe(
          ({ data, loading }) => {
            const claimLastUpdateTimeDb =
              data.getClaimProfileById && data.getClaimProfileById.lastUpdateTime;
            if (
              new Date(claimLastUpdateTimeDb).toISOString() ===
              new Date(lastUpdateTime).toISOString()
            ) {
              this.querySubscriptions.push(
                this.sbTableService.reScheduleJob(this.sbTableService.selectedValue).subscribe(
                  // tslint:disable-next-line: no-shadowed-variable
                  (data) => {
                    this.isLoading = false;
                    this.isDisabled = false;
                    const response = copy(data);
                    const { bookingAppointment, jobResponse } = response;
                    this.serviceOrderDataService.serviceBenchModal.previousJobDetails = copy(
                      this.serviceOrderDataService.serviceBenchModal.scheduleJobDetails,
                    );
                    this.serviceOrderDataService.serviceBenchModal.scheduleJobDetails =
                      bookingAppointment;
                    if (jobResponse && jobResponse.msgStatus === 'ERROR') {
                      this.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: `ERROR: ${jobResponse.statusDetails.statusDetail.errorText}.`,
                      });
                    } else {
                      this.isLoading = false;
                      this.formSubmitEvent.emit(true);
                      this.displayDialog = false;
                    }
                  },
                  (err) => {
                    this.isLoading = false;
                    this.isDisabled = false;
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: `ERROR: There was an error rescheduling the job.`,
                    });
                  },
                ),
              );
            } else {
              this.serviceOrderDataService.isLastUpdateTimeError.next(true);
              this.displayDialog = false;
              return false;
            }
          },
          (err) => {
            this.isLoading = false;
            this.isDisabled = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `ERROR: There was an error while getting last update time.`,
            });
          },
        ),
    );
  }
}
