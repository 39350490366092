import { Component, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TransactionManagementService } from './transaction-management.service';
import { buttonStatus } from '../../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  ellipsisTransactionManagmentAddOptions,
  ellipsisTransactionManagment,
} from '../../../shared/constants/ellipsis-options';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { KeyMap } from '../../../shared/interface/key-map.interface';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { DropdownDataService } from '../../../services/dropdown.data.service';
import { getLabelValueList } from '../../../shared/utilities/common-utilities';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
@Component({
  selector: 'transaction-management',
  templateUrl: 'transaction-management.template.html',
  styleUrls: ['transaction-management.scss'],
  providers: [TransactionManagementService, MessageService],
})
export class TransactionManagementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() searchParams: any;
  dataList: any[];
  admin: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  selectedRowIndex: number;
  selectedRow: any;
  ellipsisOptions: any[] = ellipsisTransactionManagment;
  ellipsisHeaderOptions: any[] = ellipsisTransactionManagmentAddOptions;
  selectedEllipsisItem: any;
  isLoading: boolean;
  querySubscription: Subscription;
  currentYear: number;
  displayHistory = false;
  clonedRowData: KeyMap = {};
  historyData: any;
  isEditing: boolean;
  displayServiceInfoModal = false;
  querySubscriptions: Subscription[] = [];
  dropdown: any;
  transactionEdit: boolean;

  constructor(
    private transactionManagementService: TransactionManagementService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private dropdownDataService: DropdownDataService,
    private roleService: RoleAuthorizationService,
  ) {}

  ngOnInit() {
    if (this.searchParams === 'Transaction Management') {
      this.currentYear = new Date().getFullYear();
    }
    this.transactionEdit = !this.roleService.validateIfPermissionsExist({
      type: 'checkBox',
      operation: 'EDIT',
      permission: 'Transaction Management',
      category: 'User Security',
    });
    const selectedYear = this.currentYear ? this.currentYear.toString() : new Date().getFullYear().toString();
    this.getTransactionManagementResults(selectedYear);
    this.cols = [
      { field: 'transactionYear', header: 'YEAR', type: 'text' },
      { field: 'transactionMonth', header: 'MONTH', type: 'text' },
      { field: 'closed', header: 'CLOSED', type: 'checkbox' },
    ];
    this.dropDownTransactionYear();
    this.dropdown = {
      transactionYear: [],
    };
  }

  dropDownTransactionYear() {
    this.querySubscriptions.push(
      this.transactionManagementService.getTransactionYear().subscribe(({ data, loading }: any) => {
        let dt = getLabelValueList(data.getTransactionYear);

        dt = [...dt];
        this.dropdown.transactionYear = dt;
        this.dropdownDataService.transactionYear = data.getTransactionYear;
      }),
    );
  }

  getTransactionManagementResults(year) {
    this.isLoading = true;
    this.querySubscription = this.transactionManagementService
      .getTransactionManagement(year)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          if (data.getTransactionManagement) {
            const modifedData = data.getTransactionManagement.map((item) => {
              return { ...item };
            });

            this.dataList = [...modifedData];
          }
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel, rowIndex) {
    this.selectedItem = item;
    this.selectedRowIndex = rowIndex;
    overlaypanel.toggle(event);
    this.selectedRow = item;
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchParams.currentValue.year) {
      this.currentYear = changes.searchParams.currentValue.year.key;
    }
  }

  onChecked(rowData) {
    console.log(rowData);
    delete this.clonedRowData[rowData.id];
    rowData.insertUserName = this.azureService.accountId;
    // rowData.effectiveDateFormatted = generalDateFormatter(rowData.effectiveDateFormatted);
    const payLoad = rowData;

    this.isLoading = true;
    this.transactionManagementService.createUpdateTransaction(payLoad).subscribe(
      ({ data }) => {
        // this.isEditing = false;
        this.isLoading = false;
        const response = data.createUpdateTransaction;
        if (response) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Saved successfully.',
          });
        }
      },
      (err) => {
        this.isLoading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error.',
        });
      },
    );
  }

  onClickView(event) {
    this.selectedRow = this.dataList[this.selectedRowIndex];
    const id = this.selectedRow && this.selectedRow.transactionManagementIdOriginal;
    if (id) {
      this.historyData = {
        type: 'transaction_management',
        id,
      };
      this.displayHistory = true;
    }
  }

  onClose(event) {
    if (event) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Closed History Tab successfully.',
      });
    }
  }

  onClickAdd(event) {
    this.isEditing = true;
    this.selectedRow = this.selectedRowIndex ? this.dataList[this.selectedRowIndex] : null;
    this.displayServiceInfoModal = true;
  }

  onCreateUpdate(event) {
    if (event) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Saved successfully.',
      });
    }
    this.dropDownTransactionYear();
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }
}
