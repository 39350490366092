<div class="crm-container">
  <app-section-header [title]="'Process Claim Results ( ' + totalRecords  + ' item(s) found )'">
    <button class="header-button" type="button" id="btn-add" label="Add" (click)="getClaimItemsCount()">Bulk process</button>
    <button class="header-button" type="button" id="btn-add" label="Add" (click)="remit()">Remit</button>
  </app-section-header>
  <p-toast></p-toast>
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-table styleClass="p-datatable-gridlines" class="scrollableDataTable" #dt [columns]="selectedColumns"
      [value]="dataList" dataKey="serviceOrderNumber" [hidden]="isLoading" [(selection)]="selectedClaims"><ng-template
        pTemplate="caption">
        <div class="multi-select">
          <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
            selectedItemsLabel="{0} columns selected" defaultLabel="Choose Columns"></p-multiSelect>
        </div>
      </ng-template><ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3em">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template><ng-template pTemplate="body" let-rowData let-columns="columns" let-ri="rowIndex">
        <tr [pSelectableRow]="rowData">
          <td><p-tableCheckbox [value]="rowData"></p-tableCheckbox></td>
          <td *ngFor="let col of columns">
            <button *ngIf="col.type === 'status'"
              [class]="btnStatus[rowData[col.field].toLowerCase()]">{{rowData[col.field]}}</button>
            <div *ngIf="col.type === 'text' && !col.isAmount">{{rowData[col.field]}}</div>
            <div *ngIf="col.type === 'text' && col.isAmount">{{rowData[col.field] | number : '1.2-2'}}</div>
          </td>
        </tr>
      </ng-template></p-table><button class="btn-crm-success" type="button" (click)="getClaimProcessSearchResult()"
      [attr.disabled]="disableLoadMore?true:null" [hidden]="isLoading">
      Load More
    </button>
  </div>
</div>
<p-dialog header="Process Claim Payment" [(visible)]="displayImport" showeffect="fade" [modal]="true"
  [styleClass]="'note-dailog'">
  <div class="process-claim-form-modal" *ngIf="form">
    <p>Claim Payment extract ready to be generated.</p>
    <form class="content" [formGroup]="form">
      <!-- (ngSubmit)="onSubmit()" -->
      <div class="form-row" *ngFor="let formField of formFields">
        <form-field [formField]="formField" [form]="form"></form-field>
      </div>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="button" icon="fa fa-close" (click)="exportToCSV()" label="Yes">Run
        Extract</button><button class="crm-button" type="button" icon="fa fa-close" (click)="displayImport=false"
        label="No">Cancel</button>
    </div>
  </ng-template>
</p-dialog>
<p-dialog header="Bulk Process Claim Payment" [(visible)]="displayBulkClaimProcess" showeffect="fade" [modal]="true"
  [styleClass]="'note-dailog'">
  <div class="process-claim-form-modal" *ngIf="formModal">
    <form class="content" [formGroup]="formModal">
      <!-- (ngSubmit)="onSubmit()" -->
      <div class="form-row" *ngFor="let formField of formModalFields">
        <form-field [formField]="formField" [form]="formModal"></form-field>
      </div>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="button" icon="fa fa-close" (click)="onBulkProcessSubmit()">Submit</button>
      <button class="crm-button" type="button" icon="fa fa-close" (click)="displayBulkClaimProcess=false">Cancel</button>
    </div>
  </ng-template>
</p-dialog>
<p-dialog header="Confirm - Bulk Process Claim Payment" [(visible)]="displayConfirmBulkClaimProcess" showeffect="fade" [modal]="true"
  [styleClass]="'note-dailog'">
  <div class="process-claim-form-modal" *ngIf="formConfirmBulkProcessGroup">
    <form class="content" [formGroup]="formConfirmBulkProcessGroup">
      <!-- (ngSubmit)="onSubmit()" -->
      <div class="form-row" *ngFor="let formField of formConfirmBulkProcess">
        <form-field [formField]="formField" [form]="formConfirmBulkProcessGroup"></form-field>
      </div>
    </form>
  </div>
  <ng-template pTemplate="footer">
    <div class="p-dialog-buttonpane ui-helper-clearfix">
      <button class="crm-button" type="button" icon="fa fa-close" (click)="exportRecords()" label="Yes">Run
        Extract</button><button class="crm-button" type="button" icon="fa fa-close" (click)="displayConfirmBulkClaimProcess=false"
        label="No">Cancel</button>
    </div>
  </ng-template>
</p-dialog>