export function getFileExtension(fileURL) {
  return fileURL.substring(fileURL.lastIndexOf('.') + 1, fileURL.length) || fileURL;
}

export function getContentType(fileExtension, fileURL = null) {
  if (fileURL) {
    fileExtension = getFileExtension(fileURL);
  }
  let contentType = 'application/json';
  switch (fileExtension) {
    case 'pdf':
      contentType = 'application/pdf';
      break;
    case 'xlsx':
      contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'png':
      contentType = 'image/png';
      break;
    case 'jpg':
      contentType = 'image/jpeg';
      break;
    case 'jpeg':
      contentType = 'image/jpeg';
      break;
    case 'csv':
      contentType = 'text/csv';
      break;
  }

  return contentType;
}

export function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export function validateInput(regex, value) {
  const patt = new RegExp(regex);
  return patt.exec(value) ? patt.exec(value)[0] : '';
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function stringToNumber(str) {
  return parseFloat(str.replace(/[-$,]/g, '')).toFixed(2);
}

export function validateEmail(email) {
  // tslint:disable-next-line: max-line-length
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isEmpty(data) {
  if (data === undefined || data === null || data === '') {
    return true;
  }
  return false;
}

export function setIfNotEmpty(data, setValue = null) {
  return isEmpty(data) ? setValue : data;
}

export function copy(aObject) {
  if (!aObject) {
    return aObject;
  }

  let v;
  const bObject = Array.isArray(aObject) ? [] : {};
  for (const k in aObject) {
    if (aObject.hasOwnProperty(k)) {
      v = aObject[k];
      bObject[k] = typeof v === 'object' ? copy(v) : v;
    }
  }
  return bObject;
}

export function removeDuplicatesFromArray(arr, key = null) {
  const filteredArr = arr.reduce((acc, current) => {
    const x =
      key !== null
        ? acc.find((item) => item[key] === current[key])
        : acc.find((item) => item === current);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  return filteredArr;
}

/**
 * To get key by value
 * @param list : List
 * @param value : String
 * @returns String
 */
export function getKeyByValue(list, value) {
  const element = list.find(
    (el) => el.value.toString().toUpperCase().trim() === value.toString().toUpperCase().trim(),
  );
  return element ? element.key : '';
}

export function getMenuTypeKey(configListOptions, menuType, desc) {
  try {
    return configListOptions
      .filter((el) => el.menuType === menuType)
      .find((el) => el.value === desc)?.key;
  } catch (e) {
    console.log(`ERROR ${e}`);
  }
  return desc;
}

export function getMenuTypeValue(configListOptions, menuType, desc) {
  try {
    return configListOptions.filter((el) => el.menuType === menuType).find((el) => el.key === desc)
      .value;
  } catch (e) {
    console.log(`ERROR ${e}`);
  }
  return desc;
}

export function getFloat(value) {
  const rawString = value ? value.replace('$', '') : value;
  return parseFloat(rawString);
}

/**
 * Clean up null values of the object
 * @param o : Object
 * @returns : Object
 */
export function sanitizeObject(o) {
  const ignores = [null, undefined, ''];
  const isNonEmpty = (d) =>
    !ignores.includes(d) && (typeof d !== 'object' || Object.keys(d).length);
  return JSON.parse(JSON.stringify(o), (k, v) => {
    if (isNonEmpty(v)) {
      return v;
    }
  });
}

/**
 * Get value by key
 * @param list List
 * @param key String
 * @returns String
 */
export function getValueByKey(list, key) {
  const element = list.find(
    (el) => el.key.toString().toUpperCase().trim() === key.toString().toUpperCase().trim(),
  );
  return element ? element.value : '';
}

/**
 * Group array based on keys
 * @param keys array of keys for group by
 * @returns object
 */
export const groupBy = (keys) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map((key) => obj[key]).join('-');
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const getLabelValueList = (data) => {
  const finalObj: any[] = [];
  data.map((el) => {
    const obj = { label: '', value: '' };
    obj.label = el.key;
    obj.value = el.value;
    finalObj.push(obj);
  });
  return finalObj;
  // tslint:disable-next-line: semicolon
};

// Get the same value for label and value.
export const getSameLabelValueList = (data) => {
  const finalObj: any[] = [];
  data.map((el) => {
    const obj = { label: '', value: '' };
    obj.label = el.key;
    obj.value = el.key;
    finalObj.push(obj);
  });
  return finalObj;
  // tslint:disable-next-line: semicolon
};
