import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CheckBoxField } from '../shared/form-field/checkbox-field';
import { DropdownField } from '../shared/form-field/dropdown-field';
import { RadioButtonField } from '../shared/form-field/radio-button-field';
import { FormField } from '../shared/form-field/form-field';
import { TextboxField } from '../shared/form-field/textbox-field';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { CrmGraphql } from '../shared/constants/graphql-constants';

const searchQuery = gql`
  query(
    $claimNumber: String
    $status: String
    $payeeName: String
    $payeeNumber: String
    $payeeType: String
    $contractNumber: String
    $dealer: String
    $insuranceCarrier: String
    $paymentType: String
    $approvedAmount: String
    $approvedForPaymentFrom: String
    $approvedForPaymentTo: String
    $limit: Int
    $offset: Int
  ) {
    getClaimProcessSearchResults(
      serviceOrderNumber: $claimNumber
      status: $status
      payeeName: $payeeName
      payeeNumber: $payeeNumber
      payeeType: $payeeType
      contractNumber: $contractNumber
      dealer: $dealer
      insuranceCarrier: $insuranceCarrier
      paymentType: $paymentType
      approvedAmount: $approvedAmount
      approvedForPaymentFrom: $approvedForPaymentFrom
      approvedForPaymentTo: $approvedForPaymentTo
      offset: $offset
      limit: $limit
    ) {
      claimIdOriginal
      claimNumber
      claimStatusDescription
      name
      number
      payeeType
      contractNumber
      dealerGroupName
      approvedAmount
      insuranceCarrier
      customerFirstName
      customerLastName
      productModelNumber
      approvedForPaymentDate
      approvedForPaymentBy
      buyingGroupNumber
      dealerGroupNumber
      tierDescription
      customerAddress
      customerAddress2
      customerCity
      customerState
      customerZipcode
      invoiceNumber
      sUniqueId
      productManufacturerName
      productModelName
      productSerialNumber
      productCategory
      productSubCategory
      productPurchasePrice
      productPurchaseDate
      failureDate
      failureReportedDate
      paymentMethodCode
      insCode
      fedTax
      claimPayMethod
      storeLocationNumber
      failure
      failureDescription
      correctiveAction
      coverageCode
      coverageDescription
      dateOfRepair
      dateOfInvoice
      servicerAddress
      servicerAddress2
      servicerCity
      servicerState
      servicerZipcode
      symptomFixNote
      serviceOrderNumber
      planDateofPurchase
      correctiveActionDescription
    }
  }
`;

const getBulkClaimProcessItemsQuery = gql`
  query(
    $claimNumber: String
    $status: String
    $payeeName: String
    $payeeNumber: String
    $payeeType: String
    $contractNumber: String
    $dealer: String
    $insuranceCarrier: String
    $paymentType: String
    $approvedAmount: String
    $approvedForPaymentFrom: String
    $approvedForPaymentTo: String
    $limit: Int
  ) {
    getBulkClaimProcessItems(
      serviceOrderNumber: $claimNumber
      status: $status
      payeeName: $payeeName
      payeeNumber: $payeeNumber
      payeeType: $payeeType
      contractNumber: $contractNumber
      dealer: $dealer
      insuranceCarrier: $insuranceCarrier
      paymentType: $paymentType
      approvedAmount: $approvedAmount
      approvedForPaymentFrom: $approvedForPaymentFrom
      approvedForPaymentTo: $approvedForPaymentTo
      limit: $limit
    ) {
      totalClaims
      totalApprovedAmount
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class ClaimTypeService {
  constructor(private apollo: Apollo) {}

  getClaimProcessSearchResult = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: searchQuery,
      variables: {
        ...searchParams,
        status: searchParams.status && searchParams.status.value,
        paymentType: searchParams.paymentType && searchParams.paymentType.value,
        offset: searchParams.offset ? parseInt(searchParams.offset, 10) : 0,
        limit: searchParams.limit ? parseInt(searchParams.limit, 10) : 20
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

    getBulkClaimProcessItemsCount = searchParams =>
    this.apollo.use(CrmGraphql.NameSpace).query<any>({
      query: getBulkClaimProcessItemsQuery,
      variables: {
        ...searchParams,
        status: searchParams.status && searchParams.status.value,
        paymentType: searchParams.paymentType && searchParams.paymentType.value,
        limit: searchParams.limit ? parseInt(searchParams.limit, 10) : null
      },
      fetchPolicy: 'network-only'
      // tslint:disable-next-line: semicolon
    });

  getClaimPaymentTypes(): Observable<any[]> {
    return of([
      {
        history_action: 'Updated',
        updated_time: '10/22/2015 12:01:27 PM',
        user: 'John Doe',
        update_details: 'Uploaded file'
      },
      {
        history_action: 'Updated',
        updated_time: '10/22/2015 12:01:27 PM',
        user: 'John Doe',
        update_details: 'Uploaded file'
      },
      {
        history_action: 'Updated',
        uploaded_time: '10/22/2015 12:01:27 PM',
        user: 'John Doe',
        update_details: 'Uploaded file'
      }
    ]);
  }
  getProcessClaimModalFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'claimSelected',
        label: 'Claims Selected',
        order: 1
      }),
      new TextboxField({
        key: 'totalPayments',
        label: 'Total Payments',
        order: 2
      }),
      new DropdownField({
        key: 'fileFormat',
        label: 'File Format',
        isSingleselect: true,
        value: { key: 'PeachTree', value: 'PeachTree' },
        options: [
          { key: 'QuickBooks IIF', value: 'QuickBooks IIF' },
          { key: 'PeachTree', value: 'PeachTree' },
          { key: 'SAP Extract', value: 'SAP Extract' },
          { key: 'PCRS Standard', value: 'PCRS Standard' }
        ],
        order: 3
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getBulkProcessClaimModalFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'maxNoOfClaimsForBulkProcess',
        label: 'Number of Claims present for current filter',
        disabled: true,
        order: 1
      }),
      new TextboxField({
        key: 'totalApprovedAmountForBulkProcess',
        label: 'Total approved amount',
        disabled: true,
        order: 2
      }),
      new TextboxField({
        key: 'NoOfClaimsForBulkProcess',
        label: 'Enter number of Claims to process',
        order: 3
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  ConfirmBulkProcessClaim() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'NoOfClaimsForBulkProcess',
        label: 'Number of Claims entered',
        disabled: true,
        order: 1
      }),
      new TextboxField({
        key: 'totalPaymentsForBulkProcess',
        label: 'Total Payments',
        disabled: true,
        order: 2
      })
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

}
