import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CheckBoxField } from '../../shared/form-field/checkbox-field';
import { DropdownField } from '../../shared/form-field/dropdown-field';
import { TextboxField } from '../../shared/form-field/textbox-field';
import { TextboxSearchField } from '../../shared/form-field/textbox-search';
import { FormField } from '../../shared/form-field/form-field';
import { TextAreaField } from '../../shared/form-field/textarea-field';
import { DropdownDataService } from '../../services/dropdown.data.service';
import { EmailPattern, PhonePattern } from '../../shared/constants/validation-regex';
import { dateTimeFormatterCST, generalDateFormatter } from '../../shared/utilities/date-utilities';
import { ServiceOrderDataService } from './service-order-data.service';
import { ClaimsLookup } from '../../shared/constants/lookup-list';
import { Role } from 'src/app/services/role-authorization.mock';

@Injectable()
export class ServiceOrderService {
  constructor(
    private dropdownDataService: DropdownDataService,
    private serviceOrderDataService: ServiceOrderDataService,
    private claimsLookupService: ClaimsLookup,
  ) {}
  selectedServicerValue: any;
  selectedServicer = new Subject<any>();
  isCustomerDispatched = new Subject<boolean>();
  onCustomerDispatchChange = new Subject<boolean>();
  public isCreditCardServicerDispatch = new BehaviorSubject(false);
  public claimDataChanged = new BehaviorSubject(false);
  public claimNotesChanged = new BehaviorSubject(false);
  public isClaimDataUpdated = new BehaviorSubject(false);
  public isNoteAddedByUser = new BehaviorSubject(false);
  public refreshClaimOnUpdate = new BehaviorSubject(false);

  getServiceOrderInfo(serviceOrder): Observable<any[]> {
    return of([
      {
        key: 'serviceOrder',
        label: 'Service Order #',
        value:
          serviceOrder.serviceOrderNumber || serviceOrder.claimNumber
            ? serviceOrder.serviceOrderNumber
              ? serviceOrder.serviceOrderNumber
              : serviceOrder.claimNumber
            : 'TBD',
      },
      { key: 'uniqueIdentifier', label: 'Unique Identifier #', value: serviceOrder.uniqueId },
      { key: 'status', label: 'Status', value: serviceOrder.claimStatusDescription },
      {
        key: 'contract',
        label: 'Contract #',
        value: serviceOrder.contractNumber,
        type: 'Contract',
      },
      { key: 'model', label: 'Model #', value: serviceOrder.productModelNumber },
      {
        key: 'serial',
        label: 'Serial #',
        value: serviceOrder.productSerialNumber
          ? serviceOrder.productSerialNumber
          : serviceOrder.coveredProduct
          ? serviceOrder.coveredProduct.serialNumber
          : null,
        type: 'Serial',
      },
      { key: 'createdBy', label: 'Created by', value: serviceOrder.createdBy },
      {
        key: 'startedOn',
        label: 'Started on',
        value: serviceOrder.claimDate ? dateTimeFormatterCST(serviceOrder.claimDate) : null,
      },
      { key: 'lastUpdatedBy', label: 'Last Updated by', value: serviceOrder.lastUpdatedBy },
      {
        key: 'lastUpdateDate',
        label: 'Last Updated',
        value:
          serviceOrder.lastUpdateTime != null
            ? dateTimeFormatterCST(serviceOrder.lastUpdateTime)
            : null,
      },
    ]);
  }

  getServicerInfo(): Observable<any[]> {
    return of([
      {
        key: 'serviceCenterCode',
        label: 'Service Center Code',
        value: 'SP--2769-FAR',
        type: 'ServiceCenter',
      },
      {
        key: 'serviceCenterName',
        label: 'Service Center Name',
        value: 'FAST Appliance Repair Inc',
      },
      { key: 'serviceCenterPhone', label: 'Service Center Phone', value: '(123) 456-7890' },
      { key: 'serviceCenterFax', label: 'Service Center Fax', value: '' },
      { key: 'serviceCenterEmail', label: 'Service Center Email', value: 'nl@nobody.com' },
    ]);
  }

  getSODealerInfoList(data?): any[] {
    let phoneNumber = '';
    if (data && data.contactInfo) {
      phoneNumber = data.contactInfo.phoneNumber1
        ? data.contactInfo.phoneNumber1
        : data.contactInfo.businessPhone;
    }
    return [
      { label: 'Dealer Name', value: data ? data.dealerGroupName : '' },
      { label: 'Dealer Code', value: data ? data.dealerGroupNumber : '' },
      { label: 'Dealer Phone', value: phoneNumber },
      { label: 'Self Servicing', value: data ? data.tSelfServicing : false },
      { label: 'Selling Retailer', value: data ? data.storeLocationName : '' },
      { label: 'Buying Group', value: data ? data.buyingGroup : '' },
    ];
  }

  getSOCustomerInfoList(contactInfo?): any[] {
    return [
      {
        label: 'Customer Name',
        value: contactInfo
          ? `${contactInfo.customerFirstName ? contactInfo.customerFirstName : ''}
        ${contactInfo.customerLastName ? contactInfo.customerLastName : ''}`
          : '',
      },
      { label: 'Email', value: contactInfo ? contactInfo.customerEmail : '' },
      { label: 'Prime Phone', value: contactInfo ? contactInfo.customerPhone : '' },
      { label: 'Fax', value: contactInfo ? contactInfo.fax : '' },
      { label: 'Secondary Phone', value: contactInfo ? contactInfo.phoneNumber2 : '' },
    ];
  }

  getSOProductLocationList(mailingAddress?): any[] {
    return [
      { label: 'Business', value: '' },
      { label: 'Address Line 1', value: mailingAddress ? mailingAddress.address1 : '' },
      { label: 'Postal Code', value: mailingAddress ? mailingAddress.zipCode : '' },
      { label: 'City', value: mailingAddress ? mailingAddress.city : '' },
      { label: 'Country', value: mailingAddress ? mailingAddress.country : '' },
    ];
  }

  getSOProductInfoList(coveredProducts?): any[] {
    return [
      {
        label: 'Manufacturer',
        value: coveredProducts ? coveredProducts.manufacturerManufacturer : '',
      },
      { label: 'Model', value: coveredProducts ? coveredProducts.modelNumber : '' },
      { label: 'Condition', value: coveredProducts ? coveredProducts.type : '' },
      {
        label: 'Plan DOP',
        value: coveredProducts ? generalDateFormatter(coveredProducts.saleDate) : '',
      },
      {
        label: 'Product Purchase Date',
        value: coveredProducts ? generalDateFormatter(coveredProducts.productPurchaseDate) : '',
      },
      { label: 'Category', value: coveredProducts ? coveredProducts.categoryDescription : '' },
      { label: 'Service Location', value: coveredProducts ? coveredProducts.serviceLocation : '' },
      {
        label: 'Subcategory',
        value: coveredProducts ? coveredProducts.subcategoryDescription : '',
      },
      {
        label: 'Deductible Collected By',
        value: coveredProducts ? coveredProducts.deductibleCollectedBy : '',
      },
    ];
  }

  getProblemInfo(): Observable<any[]> {
    return of([
      { key: 'manufacturer', label: 'Manufacturer', value: 'FRIGIDAIRE()' },
      { key: 'model', label: 'Model', value: 'FGHB2866F' },
      { key: 'serialNumber', label: 'Serial Number', value: '4A64309474' },
      { key: 'modelSuffix', label: 'Model Suffix', value: '' },
      { key: 'condition', label: 'Condition', value: 'New' },
      { key: 'planDOP', label: 'Plan DOP', value: '2/15/4440' },
      { key: 'productPurchaseDate', label: 'Product Purchase Date', value: '11/15/2016' },
      { key: 'category', label: 'Category', value: 'APPLIANCE' },
      { key: 'serviceLocation', label: 'Service Location', value: 'In Home' },
      { key: 'subCategory', label: 'Subcategory', value: 'REFRIGERATOR-OTHER(REF-REF)' },
      { key: 'deductibleCollectedBy', label: 'Deductible Collected By', value: 'Servicer' },
    ]);
  }

  getSOProductTableInfo(): Observable<any[]> {
    return of([
      {
        title: 'Used for Product',
        paid_claim_amount: '$0.00',
        limitOfLiability: '0%',
      },
      {
        title: 'Remaining for Product',
        paid_claim_amount: '$0.00',
        limitOfLiability: '0%',
      },
      {
        title: 'Product Purchase Price',
        paid_claim_amount: '$0.00',
        limitOfLiability: '0%',
      },
      {
        title: 'Adjusted Product Value',
        paid_claim_amount: '$0.00',
        limitOfLiability: '0%',
      },
    ]);
  }

  getFailureReport(): Observable<any[]> {
    return of([
      {
        failure: 'Not making ice',
        cause: 'Normal wear and tear. Need to replace ice maker',
        correctiveAction: 'Repaired',
      },
      {
        failure: 'Not making ice',
        cause: 'Normal wear and tear. Need to replace ice maker',
        correctiveAction: 'Repaired',
      },
    ]);
  }

  getProblemTableDetails(): Observable<any[]> {
    return of([
      {
        id: 0,
        type: 'Feature Description',
        note: 'Not making ice',
        entered_time: '04/06/2020 3:38:21 PM',
        entered_by: 'John DOe',
      },
      {
        id: 1,
        type: 'Additional Troubleshooting Description',
        note: 'ICEMAKER NOT WORKING',
        entered_time: '04/07/2020 3:38:21 PM',
        entered_by: 'SERVICE @ GERHARDS',
      },
    ]);
  }

  getSearchOrderFormFields() {
    const optionsUserList = this.dropdownDataService.userListData
      ? this.dropdownDataService.userListData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];
    optionsUserList.unshift({ key: '', value: '' });
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'claimStatusDescription',
        label: 'Status Change',
        filter: true,
        isSingleselect: true,
        value: { key: 'DRAFT', value: 'DRAFT' },
        options: [{ key: 'DRAFT', value: 'DRAFT' }],
        required: true,
        order: 1,
      }),
      // new DropdownField({
      //   key: 'claimPriorityDescription',
      //   label: 'Priority',
      //   isSingleselect: true,
      //   value: { key: 'Medium', value: 'Medium' },
      //   options: [
      //     { key: 'Low', value: 'Low' },
      //     { key: 'Medium', value: 'Medium' },
      //     { key: 'High', value: 'High' },
      //   ],
      //   order: 2,
      // }),
      new TextboxField({
        key: 'claimFollowUpDate',
        label: 'Follow Up Date',
        type: 'Date',
        order: 2,
      }),
      //SR-940840
      new DropdownField({
        key: 'actionRequired',
        label: 'Action required',
        isSingleselect: true,
        options: [],
        order: 3,
      }),
      new DropdownField({
        key: 'assignedTo',
        label: 'Assigned To',
        filter: true,
        isSingleselect: true,
        options: optionsUserList,
        order: 4,
        hasPermissions: true,
        permissions: {
          type: 'input',
          operation: 'EDIT-READ',
          permission: 'Assigned To',
          category: 'Claim',
        },
      }),
      new DropdownField({
        key: 'serviceType',
        label: 'Service Type',
        isSingleselect: true,
        value: { key: 'Service', value: 'Service' },
        options: [
          { key: 'Service', value: 'Service' },
          { key: 'Replacement/Store Credit', value: 'Replacement/Store Credit' },
          { key: 'Parts Vendor', value: 'Parts Vendor' },
          { key: 'Other', value: 'Other' },
        ],
        order: 5,
      }),
      new DropdownField({
        key: 'customerFeedback',
        label: 'Customer Feedback',
        isSingleselect: true,
        options: [],
        order: 6,
      }),
      new CheckBoxField({
        key: 'jsy_',
        label: '',
        type: 'checkbox',
        options: [{ key: 'jsyFlag', value: 'JSY' }],
        order: 7,
      }),
      new TextboxField({
        key: 'dispatchDate',
        label: 'Dispatch Date',
        disabled: true,
        type: 'Date',
        order: 9,
      }),
      new CheckBoxField({
        key: 'customerDispatch_',
        label: '',
        type: 'checkbox',
        disabled: false,
        options: [{ key: 'customerDispatch', value: 'Customer Dispatch' }],
        order: 10,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getTechnicalFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'dateOfRepair',
        label: 'Date of Repair',
        type: 'Date',
        order: 1,
      }),
      new TextboxField({
        key: 'insuranceCarrier',
        label: 'Insurance Carrier',
        order: 2,
      }),
      new DropdownField({
        key: 'numberOfVisits',
        label: 'Number Of Visits',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 3,
      }),
      new TextboxField({
        key: 'technicianName',
        label: 'Technician Name',
        order: 4,
      }),
      new TextboxField({
        key: 'invoiceNumber',
        label: 'Invoice Number',
        order: 5,
      }),
      new TextboxField({
        key: 'dateOfInvoice',
        label: 'Date of Invoice',
        type: 'Date',
        order: 6,
      }),
      new DropdownField({
        key: 'serviceStatus',
        label: 'Service Status',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 7,
      }),
      new TextboxField({
        key: 'serviceScheduleDate',
        label: 'Service Schedule Date',
        type: 'Date',
        order: 8,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getClaimPaymentPayeeFields() {
    const optionsClaimPaymentMethod = this.dropdownDataService.claimPaymentMethodData
      ? this.dropdownDataService.claimPaymentMethodData.map((x) => ({
          key: x.name,
          value: x.claimPayMethod,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'servicerName',
        disabled: true,
        label: 'Name',
        order: 1,
      }),
      new TextboxField({
        key: 'fedTax',
        disabled: true,
        label: 'Tax ID',
        order: 2,
      }),
      new TextboxField({
        key: 'firstName',
        disabled: true,
        label: 'Contact First Name',
        order: 3,
      }),
      new TextboxField({
        key: 'lastName',
        disabled: true,
        label: 'Contact Last Name',
        order: 4,
      }),
      new TextboxField({
        key: 'claimPayMethod',
        disabled: true,
        label: 'Payment Method ',
        order: 5,
      }),
      new TextboxField({
        key: 'servicerNumber',
        disabled: true,
        label: 'SP Code',
        order: 6,
      }),
      new TextboxField({
        key: 'claimPaymentDate',
        label: 'Claim Payment Date',
        type: 'Date',
        order: 7,
        hasPermissions: true,
        permissions: {
          type: 'input',
          operation: 'EDIT',
          permission: 'Claim Payment Date',
          category: 'Claim',
        },
      }),
      new TextboxField({
        key: 'checkNumber',
        label: 'Check Number',
        order: 8,
        hasPermissions: true,
        permissions: {
          type: 'input',
          operation: 'EDIT',
          permission: 'Check Number',
          category: 'Claim',
        },
      }),
      new TextboxField({
        key: 'checkAmount',
        disabled: true,
        label: 'Check Amount',
        order: 9,
      }),
      new TextboxField({
        key: 'phoneNumber1',
        disabled: true,
        label: 'Business Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 10,
      }),
      new TextboxField({
        key: 'externalBatchNumber',
        disabled: true,
        label: 'External Batch Number',
        order: 11,
      }),
      new TextboxField({
        key: 'email',
        disabled: true,
        label: 'Email Account',
        order: 12,
      }),
      new TextboxField({
        key: 'externalBatchSequenceNumber',
        disabled: true,
        label: 'External Sequence #',
        order: 13,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getAuditTabFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'auditStartDate',
        label: 'Audit Start Date',
        type: 'Date',
        order: 1,
      }),
      new TextboxField({
        key: 'auditEndDate',
        label: 'Audit End Date',
        type: 'Date',
        order: 2,
      }),
      new DropdownField({
        key: 'auditedBy',
        label: 'Audited By',
        options: [
          { key: 'option1', value: 'option1' },
          { key: 'option2', value: 'option2' },
          { key: 'option3', value: 'option3' },
          { key: 'option4', value: 'option4' },
        ],
        order: 3,
      }),
      new DropdownField({
        key: 'auditStatus',
        label: 'Audit Status ',
        options: [],
        order: 4,
      }),
      new DropdownField({
        key: 'auditReason',
        label: 'Audit Reason ',
        options: [],
        order: 5,
      }),

      new DropdownField({
        key: 'auditOutcome',
        label: 'Audit Outcome ',
        options: [],
        order: 6,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getCreditCardInfoTabFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'companyName',
        label: 'Company Name',
        required: true,
        order: 1,
      }),
      new TextboxField({
        key: 'nameCC',
        label: 'DBA',
        required: true,
        order: 2,
      }),
      new TextboxField({
        key: 'providerAddress',
        label: 'S/P Address',
        required: true,
        order: 3,
      }),
      new TextboxField({
        key: 'phoneNumber',
        label: 'Phone Number',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        required: true,
        order: 4,
      }),
      new TextboxField({
        key: 'initialFee',
        label: 'Initial Fee',
        isChangeEvent: true,
        required: true,
        order: 5,
      }),
      new TextboxField({
        key: 'poc',
        label: 'Point Of Contact',
        required: true,
        order: 6,
      }),
      new DropdownField({
        key: 'dispatchMethod',
        label: 'Dispatch Method',
        options: [
          { key: 'Email', value: 'Email' },
          { key: 'Phone', value: 'Phone' },
        ],
        isSingleselect: true,
        required: true,
        order: 7,
      }),
      new TextboxField({
        key: 'email',
        label: 'Email',
        validationRegex: EmailPattern,
        validationMessage: 'Please enter a valid email address.',
        required: true,
        order: 8,
      }),
      new TextboxField({
        key: 'phone',
        label: 'Phone',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        required: true,
        order: 9,
      }),
      new DropdownField({
        key: 'partOrder',
        label: 'Part Order',
        isSingleselect: true,
        required: true,
        options: [
          { key: 'Yes', value: true },
          { key: 'No', value: false },
        ],
        order: 12,
      }),
      new TextboxField({
        key: 'partOrderNumber',
        label: 'Part Order Number',
        order: 13,
      }),
      new DropdownField({
        key: 'customerAddressVerified',
        label: 'Customer Address Verified',
        options: [
          { key: 'Yes', value: true },
          { key: 'No', value: false },
        ],
        isSingleselect: true,
        order: 14,
      }),
      new TextboxField({
        key: 'cCProvidedDate',
        label: 'Date CC Provided',
        type: 'Date',
        order: 15,
      }),
      new TextboxField({
        key: 'approvedAmount',
        label: 'Pre-Approved Amount',
        isChangeEvent: true,
        order: 16,
      }),
      new TextboxField({
        key: 'agentInitials',
        label: 'Agent Initials',
        required: true,
        order: 17,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getValidatorToolFields(validatorFieldData?) {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'soNumber',
        label: 'SO Number',
        value: validatorFieldData ? validatorFieldData.soNumber : '',
        order: 1,
      }),
      new TextboxField({
        key: 'SP Code',
        label: 'SP Code',
        value: validatorFieldData ? validatorFieldData.spCode : '',
        order: 2,
      }),
      new CheckBoxField({
        key: 'rsp',
        label: '',
        value: validatorFieldData ? validatorFieldData.rsp : '',
        type: 'checkbox',
        options: [{ key: 'rsp', value: 'RSP' }],
        order: 3,
      }),
      new TextboxField({
        key: 'caller',
        label: 'caller',
        value: validatorFieldData ? validatorFieldData.caller : '',
        order: 4,
      }),
      new TextboxField({
        key: 'servicer',
        label: 'Servicer',
        value: validatorFieldData ? validatorFieldData.servicer : '',
        order: 5,
      }),
      new TextboxField({
        key: 'dop',
        label: 'DOP',
        value: validatorFieldData ? validatorFieldData.dop : '',
        type: 'Date',
        order: 6,
      }),
      new TextboxField({
        key: 'manufacturer',
        label: 'Manufacturer',
        value: validatorFieldData ? validatorFieldData.manufacturer : '',
        order: 7,
      }),
      new TextboxField({
        key: 'model',
        label: 'Model',
        value: validatorFieldData ? validatorFieldData.model : '',
        order: 8,
      }),
      new TextboxField({
        key: 'serial',
        label: 'Serial',
        value: validatorFieldData ? validatorFieldData.serial : '',
        order: 9,
      }),
      new TextAreaField({
        key: 'failureDescription',
        label: 'Failure Description',
        value: validatorFieldData ? validatorFieldData.failureDescription : '',
        rows: 6,
        order: 10,
      }),
      new TextboxField({
        key: 'warranty',
        label: 'Warranty',
        value: validatorFieldData ? validatorFieldData.warranty : '',
        order: 11,
      }),
      new TextboxField({
        key: 'planSKU',
        label: 'Plan SKU',
        value: validatorFieldData ? validatorFieldData.planSKU : '',
        order: 12,
      }),

      new TextboxField({
        key: 'opp',
        label: 'OPP $',
        value: validatorFieldData ? validatorFieldData.opp : '',
        order: 13,
      }),
      new TextboxField({
        key: 'ppc',
        label: 'PPC $',
        value: validatorFieldData ? validatorFieldData.ppc : '',
        order: 14,
      }),
      new TextboxField({
        key: 'lol',
        label: 'LOL $',
        value: validatorFieldData ? validatorFieldData.lol : '',
        order: 15,
      }),
      new TextboxField({
        key: 'lolPercent',
        label: 'LOL %',
        value: validatorFieldData ? validatorFieldData.lolPercent : '',
        order: 16,
      }),
      new TextboxField({
        key: 'parts',
        label: 'Parts $',
        value: validatorFieldData ? validatorFieldData.parts : '',
        order: 17,
      }),
      new TextboxField({
        key: 'labor',
        label: 'Labor $',
        value: validatorFieldData ? validatorFieldData.labor : '',
        order: 18,
      }),
      new TextboxField({
        key: 'tax',
        label: 'Tax $',
        value: validatorFieldData ? validatorFieldData.tax : '',
        order: 19,
      }),
      new TextboxField({
        key: 'mileage',
        label: 'Mileage $',
        value: validatorFieldData ? validatorFieldData.mileage : '',
        order: 20,
      }),
      new TextboxField({
        key: 'misc',
        label: 'Misc $',
        value: validatorFieldData ? validatorFieldData.misc : '',
        order: 21,
      }),
      new TextboxField({
        key: 'deductible',
        label: 'Deductible $',
        value: validatorFieldData ? validatorFieldData.deductible : '',
        order: 22,
      }),
      new TextboxField({
        key: 'total',
        label: 'Total $',
        value: validatorFieldData ? validatorFieldData.total : '',
        order: 23,
      }),
      new TextboxField({
        key: 'lolAfter',
        label: 'LOL After $',
        value: validatorFieldData ? validatorFieldData.lolAfter : '',
        order: 24,
      }),
      new TextboxField({
        key: 'lolAfterPercent',
        label: 'LOL After %',
        value: validatorFieldData ? validatorFieldData.lolAfterPercent : '',
        order: 25,
      }),
      new TextboxField({
        key: 'attempt',
        label: 'Attempt $',
        value: validatorFieldData ? validatorFieldData.attempt : '',
        order: 26,
      }),
      new TextboxField({
        key: 'preAuth',
        label: 'Pre Auth Code',
        value: validatorFieldData ? validatorFieldData.preAuth : '',
        order: 27,
      }),
      new TextboxField({
        key: 'partDist',
        label: 'Part Dist',
        value: validatorFieldData ? validatorFieldData.partDist : '',
        order: 28,
      }),
      new DropdownField({
        key: 'estimate',
        label: 'Estimate ',
        isSingleselect: true,
        options: [
          { key: 'Approved', value: 'Approved' },
          { key: 'Denied', value: 'Denied' },
          { key: 'Pending', value: 'Pending' },
        ],
        order: 29,
      }),
      new TextAreaField({
        key: 'validatorToolSummary',
        label: 'Validator Tool Summary',
        rows: 6,
        order: 30,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getContactFormFields() {
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'callerType',
        label: 'Caller Type',
        isSingleselect: true,
        options: [],
        required: true,
        order: 1,
      }),
      new DropdownField({
        key: 'dispatchMethod',
        label: 'Customer Communication Method',
        value: { key: 'Email', value: 'E' },
        options: [
          { key: '', value: '' },
          { key: 'Email', value: 'E' },
          { key: 'Fax', value: 'F' },
          { key: 'Work', value: 'W' },
          { key: 'Mobile', value: 'M' },
          { key: 'Home', value: 'H' },
        ],
        isSingleselect: true,
        order: 2,
      }),
      new TextboxField({
        key: 'firstName',
        label: 'Caller First Name',
        required: true,
        order: 3,
      }),
      new TextboxField({
        key: 'lastName',
        label: 'Caller Last Name',
        required: true,
        order: 4,
      }),
      new TextboxField({
        key: 'phoneNumber1',
        label: 'Claim Contact Phone 1',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        required: true,
        order: 5,
      }),
      new DropdownField({
        key: 'phoneTypeCode1',
        label: 'Claim Contact Phone 1 Type',
        isSingleselect: true,
        options: [null, { key: 'Mobile', value: 'Mobile' }, { key: 'Landline', value: 'Landline' }],
        required: true,
        order: 6,
      }),
      new TextboxField({
        key: 'phoneNumber2',
        label: 'Claim Contact Phone 2',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 7,
      }),
      new DropdownField({
        key: 'phoneTypeCode2',
        label: 'Claim Contact Phone 2 Type',
        isSingleselect: true,
        options: [null, { key: 'Mobile', value: 'Mobile' }, { key: 'Landline', value: 'Landline' }],
        order: 8,
      }),
      new TextboxField({
        key: 'email',
        label: 'Claim Contact Email',
        validationRegex: EmailPattern,
        validationMessage: 'Please enter a valid email address.',
        required: true,
        order: 9,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getProductInfoFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'serialNumber',
        label: 'Serial Number',
        order: 1,
        hasPermissions: true,
        permissions: {
          type: 'input',
          operation: 'EDIT',
          permission: 'Serial Number',
          category: 'Claim',
        },
      }),
      new TextboxField({
        key: 'modelSuffix',
        label: 'Model Suffix',
        order: 2,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getGenerateAuthRequestFormFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'approvedAmount',
        label: 'Approved Amount',
        order: 1,
      }),
      new TextboxField({
        key: 'preAuthNote',
        label: 'Note',
        order: 2,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getAdminsList() {
    return ['Admin', 'Claims Manager', 'Claims 1', 'Claims 1 Plus'];
  }
  getAdminListExtended() {
    return [
      'Admin',
      'Claims Manager',
      'Claims 1',
      'Claims 1 Plus',
      '!Service Provider Independent',
      'SSD - Limited',
      'SSD - Lite',
      'SSD-Med',
      'SSD - Med Internal Notes',
      'SSD-Plus',
    ];
  }
  getServicerList() {
    return [
      '!Service Provider Independent',
      'SSD - Limited',
      'SSD - Lite',
      'SSD-Med',
      'SSD - Med Internal Notes',
      'SSD-Plus',
    ];
  }

  getCCEditList() {
    return [
      'Special Team A-CC-SN',
      'CSR 2 Plus',
      'CSR 3',
      'Claims 1',
      'Claims 1 Plus',
      'Claims Manager',
      'Admin',
      'Back Office',
      'Back Office Lead',
      'Call Center Manager',
    ];
  }

  getInProgressCCEditList() {
    return ['Claims Manager', 'Admin', 'Back Office Lead', 'Claims 1 Plus'];
  }

  getAnyStatusCCEditList() {
    return ['Admin', 'Claims Manager', 'Claims 1 Plus'];
  }

  getPendingDispachCCEditList() {
    return ['Special Team A-CC-SN', 'Claims Manager', 'Admin', 'Back Office Lead', 'Claims 1 Plus'];
  }

  getServicerProhibitedstatuses() {
    return ['APPROVED PAID', 'APPROVED FOR PAYMENT', 'VOIDED CLAIM'];
  }

  getServiceBenchCancellationFields() {
    const { cancellationReasons } = this.serviceOrderDataService.serviceBenchModal;
    let reasonOptions = [];
    if (cancellationReasons && cancellationReasons.length > 0) {
      reasonOptions = cancellationReasons.map((x) => ({ key: x.value, value: x.key }));
    }
    reasonOptions.sort((a, b) => {
      const keyA = a.key.toUpperCase();
      const keyB = b.key.toUpperCase();
      if (keyA < keyB) {
        return -1;
      }
      if (keyA > keyB) {
        return 1;
      }
      return 0;
    });

    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'cancelReason',
        label: 'Cancel Reason',
        options: reasonOptions,
        required: true,
        filter: true,
        isSingleselect: true,
        order: 1,
      }),
      new TextAreaField({
        key: 'cancelNote',
        label: 'Cancel Note',
        required: true,
        // The SB Cancel Code uses OTHER, then the dropdown text in the reason.
        // The longest dropdown length is 36 ('Customer No Longer Needs Service | '), so 80 - 36 = 44
        maxlength: 44,
        rows: 6,
        order: 2,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getServicePowerCancellationFields() {
    const { spCancellationReasons } = this.serviceOrderDataService.servicePowerModal;
    let reasonOptions = [];
    if (spCancellationReasons && spCancellationReasons.length > 0) {
      reasonOptions = spCancellationReasons.map((x) => ({ key: x.value, value: x.key }));
    }
    reasonOptions.sort((a, b) => {
      const keyA = a.key.toUpperCase();
      const keyB = b.key.toUpperCase();
      if (keyA < keyB) {
        return -1;
      }
      if (keyA > keyB) {
        return 1;
      }
      return 0;
    });

    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'cancelReason',
        label: 'Cancel Reason',
        options: reasonOptions,
        required: true,
        filter: true,
        isSingleselect: true,
        order: 1,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getAdvancedServiceOrderFields() {
    const optionsUserList = this.dropdownDataService.userListData
      ? this.dropdownDataService.userListData.map((x) => ({
          key: x.key,
          value: x.value,
        }))
      : [];
    const formFields: FormField<string>[] = [
      new DropdownField({
        key: 'status',
        label: 'Status',
        filter: true,
        isSingleselect: true,
        options: this.claimsLookupService.claimsLookup,
        order: 1,
      }),
      new TextboxField({
        key: 'claimFollowUpDate',
        label: 'Follow Up Date',
        type: 'Date',
        isPDate: true,
        selectionMode: 'range',
        order: 2,
      }),
      new TextboxField({
        key: 'servicer',
        label: 'Service Provider',
        order: 3,
      }),
      new TextboxField({
        key: 'claimNumber',
        label: 'Claim #/Service Order #',
        order: 4,
      }),
      new TextboxField({
        key: 'contractNumber',
        label: 'Contract #',
        order: 5,
      }),
      new DropdownField({
        key: 'assignedTo',
        label: 'Assigned To',
        filter: true,
        isSingleselect: true,
        options: optionsUserList,
        order: 6,
      }),
      new TextboxField({
        key: 'uniqueId',
        label: 'Unique Id',
        order: 7,
        maxlength: 45,
      }),
      new TextboxField({
        key: 'invoiceNumber',
        label: 'Invoice #',
        order: 8,
      }),
      new TextboxField({
        key: 'claimDate',
        label: 'Created Date',
        type: 'Date',
        isPDate: true,
        selectionMode: 'range',
        order: 9,
      }),
      new TextboxField({
        key: 'dateOfRepair',
        label: 'Repair Date',
        type: 'Date',
        isPDate: true,
        selectionMode: 'range',
        order: 10,
      }),
      new TextboxField({
        key: 'preApprovedDate',
        label: 'Pre-Approved Date',
        type: 'Date',
        isPDate: true,
        selectionMode: 'range',
        order: 11,
      }),
      new TextboxField({
        key: 'submitPaymentDate',
        label: 'Submit for Payment Date',
        type: 'Date',
        isPDate: true,
        selectionMode: 'range',
        order: 12,
      }),
      new TextboxField({
        key: 'approvedDate',
        label: 'Approved Date',
        type: 'Date',
        isPDate: true,
        selectionMode: 'range',
        order: 13,
      }),
      new TextboxField({
        key: 'contactFirstName',
        label: 'First Name',
        order: 14,
      }),
      new TextboxField({
        key: 'contactLastName',
        label: 'Last Name',
        order: 15,
      }),
      new TextboxField({
        key: 'phoneNumber',
        label: 'Primary Phone Number',
        validationRegex: PhonePattern,
        validationMessage: 'Please enter a valid phone number.',
        order: 16,
      }),
      new TextboxField({
        key: 'manufacturer',
        label: 'Manufacturer',
        order: 17,
      }),
      new TextboxField({
        key: 'modelNumber',
        label: ' 	Model ',
        order: 18,
      }),
      new TextboxField({
        key: 'serialNumber',
        label: ' 	Serial ',
        order: 19,
      }),
      new DropdownField({
        key: 'customerFeedback',
        label: 'Customer Feedback',
        isSingleselect: true,
        options: [
          { key: 'Left Message 1', value: 'Left Message 1' },
          { key: 'Left Message 2', value: 'Left Message 2' },
          { key: 'Left Message 3', value: 'Left Message 3' },
          { key: 'Survey Completed', value: 'Survey Completed' },
          { key: 'Satisfied', value: 'Satisfied' },
          { key: 'Follow Up Required', value: 'Follow Up Required' },
          { key: 'Scheduled', value: 'Scheduled' },
        ],
        order: 20,
      }),
      new TextboxField({
        key: 'authorizationCode',
        label: 'Pre-authorized #',
        order: 21,
      }),
      new TextboxField({
        key: 'technicianName',
        label: 'Technician Name',
        order: 22,
      }),
      new TextboxField({
        key: 'claimPaymentDate',
        label: 'Claim Payment Date',
        type: 'Date',
        isPDate: true,
        selectionMode: 'range',
        order: 23,
      }),
      new TextboxField({
        key: 'createdBy',
        label: 'Created By',
        order: 24,
      }),
    ];

    return of(formFields.sort((a, b) => a.order - b.order));
  }

  getDispatchNoJobFields() {
    const formFields: FormField<string>[] = [
      new TextboxField({
        key: 'dispatch_date',
        label: 'Dispatch Date',
        type: 'Date',
        require: true,
        order: 1,
      }),
    ];
    return of(formFields.sort((a, b) => a.order - b.order));
  }
}
