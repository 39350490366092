<div class="crm-container">
  <app-section-header [title]="'Search Dealer Location Results'" [optionHeader]="opHeader" />
  <div class="sub-section">
    <app-tab-spinner *ngIf="isLoading"></app-tab-spinner>
    <p-toast />
    <p-table styleClass="p-datatable-gridlines" class="scrollableDataTable" [columns]="cols" [value]="dataList"
      dataKey="id" editMode="row" [hidden]="isLoading">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
          <th class="last-cell" [ngClass]="{ 'row-edit' : isEditing }"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="rowData">
          <td *ngFor="let col of columns">
            <p-cellEditor>
              <ng-template *ngIf="col.type === 'text'" pTemplate="output">
                {{rowData[col.field]}}
              </ng-template>
              <ng-template *ngIf="col.type === 'text' && col.field === 'status'" pTemplate="output">
                <button [class]="btnStatus[rowData[col.field].toLowerCase()]">
                  {{rowData[col.field] | uppercase}}
                </button>
              </ng-template>
              <ng-template *ngIf="col.field === 'name'" pTemplate="output">
                <a (click)="openTab(rowData)" onmouseover="this.style.cursor='pointer'">
                  {{rowData[col.field] | uppercase}}
                </a>
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="last-cell">
            <div class="three-dots" *ngIf="!editing" (click)="ellipsisClick($event,rowData,opRow)">
              <div class="icon" [inlineSVG]="'assets/three_dots.svg'"></div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <button class="btn-crm-success" type="button" (click)="getSearchData()" [attr.disabled]="disableLoadMore?true:null"
      [hidden]="isLoading">
      Load More
    </button>
  </div>
</div>
<p-overlayPanel #opRow>
  <p-listbox [options]="ellipsisOptions" (onClick)="ellipsisOptionClick($event,opRow)"> </p-listbox>
</p-overlayPanel>
<p-overlayPanel #opHeader>
  <p-listbox [options]="ellipsisHeaderOptions"
    (onClick)="this[$event.option.callback] ? this[$event.option.callback]($event) : ellipsisOptionClick($event,opHeader)">
  </p-listbox>
</p-overlayPanel>