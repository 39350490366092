import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { SearchProfileService } from './seach-profile.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisOptions, ellipsisHeaderOptions } from '../../shared/constants/ellipsis-options';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { TabsService } from '../../services/tabs.service';
import { Tab } from '../../services/tab';
import { Subscription } from 'rxjs';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { DropdownDataService } from '../../services/dropdown.data.service';
import { copy } from '../../shared/utilities/common-utilities';
import { RolePermissionService } from '../../common/role-permission.service';
import { ModuleType } from '../../shared/constants/enums';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { CrmService } from '../../services/crm-service';

@Component({
  selector: 'search-profile',
  templateUrl: 'search-profile.template.html',
  styleUrls: ['../../shared/styles/crm-table.scss'],
  providers: [MessageService,SearchProfileService],
})
export class SearchProfileComponent implements OnInit, OnDestroy {
  @Input() searchParams: any;
  dataList: any[];
  cols: any[];
  btnStatus: any = buttonStatus;
  selectedItem: any;
  ellipsisOptions: any[] = ellipsisOptions;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  ellipsisOptionsSec: any;
  ellipsisHeaderOptionsSec: any;
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  newTab: Tab;
  querySubscription: Subscription;
  isLoading = false;
  authDetails: IRoleAuthorizationService;
  lastOffsetVal = 0;
  offsetLimit = 10;
  disableLoadMore = false;
  resultCount = 0;
  constructor(
    private searchProfileService: SearchProfileService,
    private tabService: TabsService,
    private roleService: RoleAuthorizationService,
    private dropdownDataService: DropdownDataService,
    private confirmationService: ConfirmationService,
    private azureService: AzureLoginService,
    private crmService: CrmService,
    private messageService: MessageService,
    private rps: RolePermissionService,
  ) {}

  ngOnInit() {
    this.authDetails = {
      authorizationType: AuthorizationType.OptionList,
      component: SearchProfileComponent,
      generalArray: this.ellipsisOptions,
    };
    this.ellipsisOptions = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    this.editConstant = uuidv4();
    this.cols = [
      { field: 'status', header: 'STATUS', type: 'text' },
      { field: 'number', header: 'NUMBER', type: 'text' },
      { field: 'name', header: 'DEALER NAME', type: 'text' },
      { field: 'agentName', header: 'GROUP TYPE', type: 'text' },
      { field: 'city', header: 'CITY', type: 'text' },
      { field: 'state', header: 'STATE', type: 'text' },
      { field: 'zipCode', header: 'ZIP', type: 'text' },
      { field: 'phoneNumber', header: 'PHONE', type: 'text' },
    ];

    this.getSearchData();
    this.doEvaluateRolePermissions();
  }

  private doEvaluateRolePermissions() {
    this.ellipsisHeaderOptionsSec = {
      type: 'three-dots',
      operation: 'ADD',
      permission: 'Dealer',
      category: 'Seller',
    };
    this.ellipsisHeaderOptions = this.rps.evaluate(
      this.ellipsisHeaderOptionsSec,
      this.ellipsisHeaderOptions,
    );

    this.ellipsisOptionsSec = {
      type: 'three-dots',
      operation: 'EDIT-DELETE',
      permission: 'Dealer',
      category: 'Seller',
    };
    this.ellipsisOptions = this.rps.evaluate(this.ellipsisOptionsSec, this.ellipsisOptions);
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    const callBackFunction = event.option?.callback;
    if(callBackFunction && typeof this[callBackFunction] === 'function') {
      this[callBackFunction](event,overlaypanel);
      return;
    }
    this.newTab = {
      header: 'Dealer',
      content: event.option.value == 'add' ? null : this.selectedItem.dealerGroupsIdOriginal,
      type: 'Dealer',
    };
    this.tabService.createTab(this.newTab);
    overlaypanel.hide();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    const payload = {
      idOriginal: this.selectedItem.dealerGroupsIdOriginal,
      moduleName: ModuleType.DEALER,
      azureUserId: this.azureService.accountId,
    };
    this.confirmationService.confirm({
      message: 'Are you sure, you want to delete this dealer ?',
      accept: () => {
        this.isLoading = true;
        this.crmService.deleteCrmModule(payload).subscribe({
          next: ({ data, loading }: any) => {
            this.isLoading = false;
            if (!data.deleteCrmModule) {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Dealer cannot be deleted.',
              });
              return;
            }
            const selectedRowIndex = this.dataList.indexOf(this.selectedItem);
            this.dataList.splice(selectedRowIndex, 1);
          },
          error: (err) => {
            this.isLoading = false;
            throw err;
          }
        });
      },
    });
    overlaypanel.hide();
  }
  openTab(data: any) {
    this.newTab = {
      header: 'Dealer Profile',
      content: data.dealerGroupsIdOriginal,
      type: 'Dealer',
    };
    this.tabService.createTab(this.newTab);
  }

  getSearchData() {
    this.isLoading = true;
    this.searchParams =
      this.searchParams && typeof this.searchParams !== 'string' ? this.searchParams : {};

    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;

    this.lastOffsetVal += this.offsetLimit;

    if (this.searchParams.buyingGroup) {
      this.searchParams.agentIdOriginal = this.searchParams.buyingGroup.value
        ? this.searchParams.buyingGroup.value
        : null;
    } else {
      this.searchParams.agentIdOriginal = null;
    }
    this.searchParams.status = this.searchParams.status ? this.searchParams.status : '';
    this.querySubscription = this.searchProfileService
      .getProfileDetails(this.searchParams)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const modifiedData = data.getDealerSearchResults;
          // Create a Set to store unique dealerGroupsIdOriginal values
          const uniqueDealerGroups = new Set();

          // Filter modifiedData to contain only unique dealer profiles
          const filteredModifiedData = modifiedData.filter(item => {
            if (!uniqueDealerGroups.has(item.dealerGroupsIdOriginal)) {
              uniqueDealerGroups.add(item.dealerGroupsIdOriginal);
              return true;
            }
            return false;
          });
          const finalData = filteredModifiedData.map(item => {
            const agent = this.dropdownDataService.buyingGroupData.find(
              (x) => x.agentIdOriginal === item.agentId,
            );
            return { ...item, agentName: agent ? agent.name : null };
          });
          this.disableLoadMore =
            Boolean(filteredModifiedData.length < this.offsetLimit) ||
            !Boolean(filteredModifiedData.length);
          this.dataList = !!this.dataList ? [...this.dataList, ...finalData] : [...finalData];
          this.resultCount = this.dataList.length;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }
}
