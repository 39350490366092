import gql from 'graphql-tag';

export const contractDetailsFormQuery = gql`
  query($id: Int!, $azureUserId: String) {
    getContractProfileById(id: $id, azureUserId: $azureUserId) {
      contractsId
      registerNumber
      contractsIdOriginal
      customerId
      contractNumber
      insurerName
      activationDate
      uniqueId
      dop
      cultureCode
      effectiveDate
      printedDate
      expirationDate
      shipDate
      billedDate
      storeLocationName
      storeLocationNumber
      dealerGroupName
      dealerGroupNumber
      agentName
      salesClerk
      masterBuyingGroup
      buyingGroup
      saleDate
      externalId
      salesFileName
      cancelFileName
      contractStatusCode
      contractStatusDescription
      agentNumber
      effectiveBasedOn
      isEffectiveDateOverride
      transactionDate
      productTypeDescription
    }
  }
`;
export const contractMainTabQuery = gql`
  query($id: Int!, $azureUserId: String) {
    getContractProfileById(id: $id, azureUserId: $azureUserId) {
      contractsId
      contractsIdOriginal
      productTypeDescription
      contractStatusDescription
      contactInformation(categoryType: Customer) {
        customerFirstName
        customerLastName
        customerPhone
        phoneNumber2
        customerEmail
        customerFax
        guardian
        customerIdOriginal
        phoneTypeCode1
        phoneTypeCode2
      }
      addresses(categoryType: Contract) {
        id
        address1
        address2
        state
        city
        zipCode
        country
        addressIDOriginal
      }
      coverageInformation {
        serviceLocation
        planType
        term
      }
      coveredProducts {
        productsId
        contractsId
        productNo
        contractUniqueId
        manufacturerManufacturer
        modelName
        modelNumber
        serialNumber
        type
        subcategoryCode
        subcategoryDescription
        categoryCode
        categoryDescription
        tierCode
        tierDescription
        productPurchaseDate
        installDate
        productPurchasePrice
        productRetailAmount
        partsTerm
        laborTerm
        majorComponentTerm
        otherTerm
        serviceLocation
        fulfilled
        isDeleted
        productsIdOriginal
        calculatedFields {
          mfwExp
          mfwCov
          message
        }
        lolVirtualCalculatedFields {
          liabilitySpent
          liabilityRemaining
          totalLiability
        }
      }
      associatedContracts {
        contractsId
        contractNumber
        manufacturer
        modelName
        serialNumber
        planDateOfPurchase
        planStartDate
        planExpDate
        sKUDescription
        contractsIdOriginal
      }
      general1
      general2
      general3
      general4
      storeLocationName
      storeLocationNumber
      dealerGroupName
      dealerGroupNumber
      agentName
      salesClerk
      contractNumber
      masterBuyingGroup
      buyingGroup
      externalId
      insurerNumber
      agentNumber
      saleDate
      coverageCode
      coverageDescription
      productTypeCode
      sKUReferenceCode
      effectiveBasedOn
      retailRate
      dealerInfo {
        dealerGroupsId
        tSelfServicing
        contactInfo(categoryType: DealersGroup) {
          contactId
          phoneNumber1
        }
      }
      sellerInfo {
        sellerId
        contactInfo(categoryType: Seller) {
          contactId
          phoneNumber1
        }
      }
      soClaims {
        claimIdOriginal
        claimStatusDescription
        serviceOrderNumber
        claimDate
        claimAmount {
          approvedAmount
          paymentAmount
        }
        coveredProduct {
          categoryDescription
          modelName
          subcategoryDescription
          modelNumber
        }
      }
    }
  }
`;

export const soClaimsQuery = gql`
  query($id: Int!) {
    getContractProfileById(id: $id) {
      contractsId
      soClaims {
        claimIdOriginal
        claimStatusDescription
        approvedAmount
        claimNumber
        checkAmount
        productSubCategory
        productModelName
        failureFunctionalityStatusCode
        claimDate
        repairOrderDate
        claimPriorityDescription
        actionRequired
        claimFollowUpDate
        serviceOrderNumber
        coveredProduct {
          categoryDescription
          modelName
          subcategoryDescription
          modelNumber
        }
        problem {
          symptom
        }
        servicer {
          servicerName
        }
        techinfo {
          dateOfRepair
        }
        claimServicer {
          correctiveAction
          correctiveActionNote
          claimServicerIdOriginal
        }
        claimAmount {
          approvedAmount
          paymentAmount
        }
      }
    }
  }
`;

export const rateDetailsQuery = gql`
  query($id: Int!) {
    getContractProfileById(id: $id) {
      contractsId
      contractsOriginalId
      retailRate
      cancelRetailRate
      rateDetails {
        contractNumber
        contractRatesId
        contractsId
        bucketCode
        bucketLabelDescription
        writtenAmount
        balanceAmount
        cancelledAmount
        categoryCode
      }
    }
  }
`;

export const contractCoverageQuery = gql`
  query($id: Int!, $offset: Int, $limit: Int) {
    getContractProfileById(id: $id) {
      contractsId
      coverageCode
      coverageDescription
      netRate
      retailRate
      retailTax1
      retailTax2
      grandTotal
      expirationDate
      coveredProducts {
        partsTerm
      }
      coverageInformation {
        serviceLocation
        planType
        term
      }
      coverageLossCodes(offset: $offset, limit: $limit) {
        coverageLossCodeId
        coveragePlanId
        coverageCode
        lossCode
        description
        termMonth
        type
        component
        effectiveDateBasedOn
        effectiveDateLag
        effectiveDateLagUnit
        deductibleAmount
        cost
        contractId
        coverageLossCodeIdOriginal
      }
    }
  }
`;

export const getCoveredProductQuery = gql`
  query($id: Int!) {
    getContractProfileById(id: $id) {
      contractsId
      coveredProducts {
        productsId
        fulfilled
        modelName
        manufacturerManufacturer
        serialNumber
        type
        productPurchasePrice
        productPurchaseDate
        installDate
        categoryDescription
        subcategoryDescription
        tierDescription
        partsTerm
        laborTerm
        majorComponentTerm
        otherTerm
        serviceLocation
        productsIdOriginal
        limitOfLiability
        calculatedFields {
          mfwExp
          mfwCov
          message
        }
        lolVirtualCalculatedFields {
          liabilitySpent
          liabilityRemaining
          totalLiability
        }
      }
    }
  }
`;

export const saveContractProfileAndMainTab = gql`
  mutation(
    $contractNumber: String
    $insurerName: String
    $activationDate: Date
    $uniqueId: String
    $dop: String
    $cultureCode: String
    $effectiveDate: Date
    $expirationDate: Date
    $entryDate: Date
    $transactionDate: Date
    $printedDate: Date
    $shipDate: Date
    $storeLocationName: String
    $storeLocationNumber: String
    $agentName: String
    $salesClerk: String
    $general1: String
    $general2: String
    $general3: String
    $general4: String
    $salesFileName: String
    $cancelFileName: String
    $contractsIdOriginal: Int
    $contractStatusCode: String
    $contractStatusDescription: String
    $customerInfo: ContactInputType
    $addresses: [AddressInputType]
    $insertUserName: String
    $dealerGroupNumber: String
    $dealerGroupName: String
    $insurerNumber: String
    $agentNumber: String
    $saleDate: Date
    $coverageCode: String
    $coverageDescription: String
    $productTypeCode: String
    $sKUReferenceCode: String
    $externalId: String
    $billedDate: Date
    $retailRate: Float
    $effectiveBasedOn: String
    $isEffectiveDateOverride: Boolean
  ) {
    createUpdateContract(
      contractProfileInput: {
        contractNumber: $contractNumber
        insurerName: $insurerName
        activationDate: $activationDate
        uniqueId: $uniqueId
        dop: $dop
        cultureCode: $cultureCode
        effectiveDate: $effectiveDate
        expirationDate: $expirationDate
        entryDate: $entryDate
        transactionDate: $transactionDate
        printedDate: $printedDate
        shipDate: $shipDate
        storeLocationName: $storeLocationName
        storeLocationNumber: $storeLocationNumber
        agentName: $agentName
        salesClerk: $salesClerk
        general1: $general1
        general2: $general2
        general3: $general3
        general4: $general4
        salesFileName: $salesFileName
        cancelFileName: $cancelFileName
        contractsIdOriginal: $contractsIdOriginal
        contractStatusCode: $contractStatusCode
        contractStatusDescription: $contractStatusDescription
        customerInfo: $customerInfo
        addresses: $addresses
        insertUserName: $insertUserName
        dealerGroupNumber: $dealerGroupNumber
        dealerGroupName: $dealerGroupName
        insurerNumber: $insurerNumber
        agentNumber: $agentNumber
        saleDate: $saleDate
        coverageCode: $coverageCode
        coverageDescription: $coverageDescription
        productTypeCode: $productTypeCode
        sKUReferenceCode: $sKUReferenceCode
        externalId: $externalId
        billedDate: $billedDate
        retailRate: $retailRate
        effectiveBasedOn: $effectiveBasedOn
        isEffectiveDateOverride: $isEffectiveDateOverride
      }
    ) {
      contractNumber
      insurerName
      activationDate
      uniqueId
      dop
      cultureCode
      effectiveDate
      expirationDate
      entryDate
      billedDate
      printedDate
      shipDate
      storeLocationName
      storeLocationNumber
      agentName
      salesClerk
      general1
      general2
      general3
      general4
      contractsIdOriginal
      dealerGroupNumber
      dealerGroupName
      insurerNumber
      agentNumber
      saleDate
      coverageCode
      coverageDescription
      productTypeCode
      sKUReferenceCode
      externalId
      transactionDate
      effectiveBasedOn
      isEffectiveDateOverride
    }
  }
`;

export const saveContractCoveredProducts = gql`
  mutation(
    $productsId: Int
    $contractsId: Int
    $contractsIdOriginal: Int
    $productNo: Int
    $contractUniqueId: String
    $uniqueId: String
    $manufacturerManufacturer: String
    $modelName: String
    $modelNumber: String
    $serialNumber: String
    $type: String
    $subcategoryCode: String
    $subcategoryDescription: String
    $categoryCode: String
    $categoryDescription: String
    $tierCode: String
    $tierDescription: String
    $productPurchaseDate: Date
    $installDate: Date
    $productPurchasePrice: String
    $productRetailAmount: Float
    $partsTerm: Int
    $laborTerm: Int
    $majorComponentTerm: Int
    $otherTerm: Int
    $serviceLocation: String
    $fulfilled: String
    $isDeleted: Boolean
    $productsIdOriginal: Int
    $insertUserName: String
  ) {
    CreateOrUpdateCoveredProducts(
      coveredProduct: {
        productsId: $productsId
        contractsId: $contractsId
        contractsIdOriginal: $contractsIdOriginal
        productNo: $productNo
        contractUniqueId: $contractUniqueId
        uniqueId: $uniqueId
        manufacturerManufacturer: $manufacturerManufacturer
        modelName: $modelName
        modelNumber: $modelNumber
        serialNumber: $serialNumber
        type: $type
        subcategoryCode: $subcategoryCode
        subcategoryDescription: $subcategoryDescription
        categoryCode: $categoryCode
        categoryDescription: $categoryDescription
        tierCode: $tierCode
        tierDescription: $tierDescription
        productPurchaseDate: $productPurchaseDate
        installDate: $installDate
        productPurchasePrice: $productPurchasePrice
        productRetailAmount: $productRetailAmount
        partsTerm: $partsTerm
        laborTerm: $laborTerm
        majorComponentTerm: $majorComponentTerm
        otherTerm: $otherTerm
        serviceLocation: $serviceLocation
        fulfilled: $fulfilled
        isDeleted: $isDeleted
        productsIdOriginal: $productsIdOriginal
        insertUserName: $insertUserName
      }
    ) {
      productsId
      contractsId
      productNo
      contractUniqueId
      manufacturerManufacturer
      modelName
      modelNumber
      serialNumber
      type
      subcategoryCode
      subcategoryDescription
      categoryCode
      categoryDescription
      tierCode
      tierDescription
      productPurchaseDate
      installDate
      productPurchasePrice
      productRetailAmount
      partsTerm
      laborTerm
      majorComponentTerm
      otherTerm
      serviceLocation
      fulfilled
      isDeleted
      productsIdOriginal
      calculatedFields {
        mfwExp
        mfwCov
        message
      }
      lolVirtualCalculatedFields {
        liabilitySpent
        liabilityRemaining
        totalLiability
      }
    }
  }
`;

export const contractCancellationQuery = gql`
  query($contractNumber: String!) {
    getContractCancellation(contractNumber: $contractNumber) {
      customerCancellationDate
      cancellationProcessDate
      cancellationTransactionDate
      retailCost
      baseDealerCost
      claimsApprovedPaid
      cancellationReason
      cancellationMethod
      refundToCustomerSysGen
      refundToCustomerOverride
      refundToCustomerAdjusted
      dealerRefundSysGen
      dealerRefundOverride
      dealerRefundAdjusted
      cancellationFee
      termMonth
      termMonthFactor
      startDate
      usedDays
      typeOfMethod
      proRatePercentage
      refundBeforeCancellation
      freeLookUpPeriod
      cancellationValue
      refundAfterCancellation
      finalRefund
      finalCancellationFee
      dealerExclusion
    }
  }
`;

export const transactionManagementQuery = gql`
  query($transactionYear: String!) {
    getTransactionManagement(transactionYear: $transactionYear) {
      transactionManagementIdOriginal
      transactionYear
      transactionMonth
      closed
    }
  }
`;

export const getCategoryDescriptionListQuery = gql`
  query {
    getCategoryDescriptionList
  }
`;

export const getSubcategoryDescriptionListQuery = gql`
  query {
    getAllSubCategoryCodeAndDescription {
      key
      value
    }
  }
`;
