import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy } from '@angular/core';
import { ClaimTypeService } from '../claim.service';
import { buttonStatus } from '../../shared/constants/button-class';
import { KeyMap } from '../../shared/interface/key-map.interface';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '@angular/forms';
import { FormField } from '../../shared/form-field/form-field';
import { FormCanDeactivate } from '../../shared/form-field/form-can-deactivate';
import { FormFieldControlService } from '../../services/form-field-control.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { generalDateFormatter } from '../../shared/utilities/date-utilities';
import { copy } from '../../shared/utilities/common-utilities';
import { ExportService } from '../../services/export.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { InsuranceExtractService } from '../../reports/insurance-extract/insurance-extract.service';
import moment from 'moment';
import { switchMap, takeWhile, timer } from 'rxjs';
import { ViewFileService } from '../../shared/view-file/view-file.service';

@Component({
  selector: 'process-claim',
  templateUrl: 'process-claim.template.html',
  styleUrls: ['process-claim.scss'],
  providers: [ClaimTypeService, FormFieldControlService, MessageService],
})
export class ProcessClaimComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Input() searchParams: any;
  formFields: FormField<string>[] = [];
  formModalFields: FormField<string>[] = [];
  formConfirmBulkProcess: FormField<string>[] = [];
  cols: any[];
  dataList: any[];
  btnStatus: any = buttonStatus;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  displayImport: boolean;
  displayBulkClaimProcess: boolean;
  displayConfirmBulkClaimProcess: boolean;
  MaxClaimsForBulkProcess: number;
  exportRequestsId = null;
  isLoading: boolean;
  lastOffsetVal = 0;
  offsetLimit = 2500; // 2500 should load all most of the time. If larger, need to click 'Load More' because Lambda data response limit.
  disableLoadMore: boolean;
  selectedClaims: any;
  form: FormGroup;
  formModal: FormGroup;
  formConfirmBulkProcessGroup: FormGroup;
  selectedColumns: any[];
  totalRecords = 0;
  querySubscription: any;
  public uploadSubscription: any;
  private fileStatus: number;
  uploadStartTime: any;
  CHECK_FILE_STATUS_TIMER = 6; // in Seconds
  MAX_TIMEOUT = 15 * 60; // Timeout in Seconds
  constructor(
    private messageService: MessageService,
    private claimService: ClaimTypeService,
    private cdr: ChangeDetectorRef,
    private fcs: FormFieldControlService,
    private exportService: ExportService,
    private insuranceService: InsuranceExtractService,
    private viewFileService: ViewFileService,
    private confirmationService: ConfirmationService,
    private azureService: AzureLoginService
  ) {
    super();
    this.displayImport = false;
  }
  ngOnInit() {
    this.editConstant = uuidv4();

    this.claimService.getProcessClaimModalFields().subscribe((formModalInfo) => {
      this.formFields = formModalInfo;
    });
    this.form = this.fcs.toFormGroup(this.formFields);

    this.claimService.getBulkProcessClaimModalFields().subscribe((formModalInfo) => {
      this.formModalFields = formModalInfo;
    });
    this.formModal = this.fcs.toFormGroup(this.formModalFields);

    this.claimService.ConfirmBulkProcessClaim().subscribe((formModalInfo) => {
      this.formConfirmBulkProcess = formModalInfo;
    });
    this.formConfirmBulkProcessGroup = this.fcs.toFormGroup(this.formConfirmBulkProcess);

    this.cols = [
      { field: 'claimStatusDescription', header: 'CLAIM STATUS', type: 'text' },
      { field: 'serviceOrderNumber', header: 'SERVICE ORDER #', type: 'text' },
      { field: 'insCode', header: 'INS. CODE', type: 'text' },
      {
        field: 'approvedAmount',
        header: 'TOTAL AMOUNT APPROVED FOR PAYMENT',
        type: 'text',
        isAmount: true,
      },
      { field: 'invoiceNumber', header: 'INVOICE NUMBER', type: 'text' },
      { field: 'payeeType', header: 'PAYEE TYPE', type: 'text' },
      { field: 'number', header: 'PAYEE NUMBER', type: 'text' },
      { field: 'name', header: 'PAYEE NAME', type: 'text' },
      { field: 'servicerAddress', header: 'PAYEE ADDRESS', type: 'text' },
      { field: 'servicerAddress2', header: 'PAYEE ADDRESS 2', type: 'text' },
      { field: 'servicerCity', header: 'PAYEE CITY', type: 'text' },
      { field: 'servicerState', header: 'PAYEE STATE', type: 'text' },
      { field: 'servicerZipcode', header: 'PAYEE ZIPCODE', type: 'text' },
      { field: 'fedTax', header: 'SERVICER TAX ID', type: 'text' },
      { field: 'claimPayMethod', header: 'CLAIM PAY METHOD', type: 'text' },
      { field: 'buyingGroupNumber', header: 'BUYING GROUP NUMBER', type: 'text' },
      { field: 'dealerGroupNumber', header: 'DEALER NUMBER', type: 'text' },
      { field: 'dealerGroupName', header: 'DEALER NAME', type: 'text' },
      { field: 'storeLocationNumber', header: 'DEALER LOCATION', type: 'text' },
      { field: 'sUniqueId', header: 'UNIQUE ID', type: 'text' },
      { field: 'customerFirstName', header: 'CUSTOMER FIRST NAME', type: 'text' },
      { field: 'customerLastName', header: 'CUSTOMER LAST NAME', type: 'text' },
      { field: 'customerAddress', header: 'CUSTOMER ADDRESS', type: 'text' },
      { field: 'customerAddress2', header: 'CUSTOMER ADDRESS 2', type: 'text' },
      { field: 'customerCity', header: 'CUSTOMER CITY', type: 'text' },
      { field: 'customerState', header: 'CUSTOMER STATE', type: 'text' },
      { field: 'customerZipcode', header: 'CUSTOMER ZIPCODE', type: 'text' },
      { field: 'productCategory', header: 'CATEGORY', type: 'text' },
      { field: 'tierDescription', header: 'TIER', type: 'text' },
      { field: 'productSubCategory', header: 'SUBCATEGORY', type: 'text' },
      { field: 'productManufacturerName', header: 'MANUFACTURER', type: 'text' },
      { field: 'productModelNumber', header: 'MODEL', type: 'text' },
      { field: 'productSerialNumber', header: 'SERIAL NUMBER', type: 'text' },
      { field: 'productPurchasePrice', header: 'PRODUCT PURCHASE PRICE', type: 'text' },
      { field: '_1', header: 'ADJUSTED PRODUCT VALUE', type: 'text' },
      { field: 'productPurchaseDate', header: 'PRODUCT DATE OF PURCHASE', type: 'text' },
      { field: 'planDateofPurchase', header: 'PLAN DATE OF PURCHASE', type: 'text' },
      { field: 'failureDate', header: 'FAILURE/LOSS DATE', type: 'text' },
      { field: 'failureReportedDate', header: 'REPORTED DATE', type: 'text' },
      { field: 'failure', header: 'FAILURE', type: 'text' },
      { field: 'correctiveAction', header: 'CORRECTIVE ACTION', type: 'text' },
      { field: 'dateOfInvoice', header: 'DATE OF INVOICE', type: 'text' },
      { field: 'dateOfRepair', header: 'DATE OF REPAIR', type: 'text' },
      { field: 'approvedForPaymentDate', header: 'CLAIM APPROVED FOR PAYMENT DATE', type: 'text' },
      { field: 'approvedForPaymentBy', header: 'CLAIM APPROVED FOR PAYMENT BY', type: 'text' },
      { field: 'coverageCode', header: 'SKU/COVERAGE CODE', type: 'text' },
      { field: 'coverageDescription', header: 'SKU/COVERAGE DESCRIPTION', type: 'text' },
      { field: '_3', header: 'MANUFACTURE WARRANTY', type: 'text' },
      { field: 'failureDescription', header: 'FAILURE DESCRIPTION', type: 'text' },
      {
        field: 'correctiveActionDescription',
        header: 'CORRECTIVE ACTION DESCRIPTION',
        type: 'text',
      },
      { field: 'insuranceCarrier', header: 'CARRIER', type: 'text' },
      { field: 'keepHeader1', header: 'ACCUMULATIVE CLAIMS', type: 'text' },
      { field: 'keepHeader2', header: 'CLAIMS MINUS PURCHASE', type: 'text' },
      { field: 'keepHeader3', header: 'LAST PRE APPROVED AMOUNT', type: 'text' },
      { field: 'keepHeader4', header: 'SERVICER ACCOUNT NUMBER', type: 'text' },
      { field: 'keepHeader5', header: 'GL', type: 'text' },
      { field: 'keepHeader6', header: 'SAGE VENDOR NO', type: 'text' },
      { field: 'keepHeader7', header: 'DT BILL DATE', type: 'text' },
    ];
    this.selectedColumns = this.cols.slice(0, 6);
    this.getClaimProcessSearchResult();
  }

  onYes() {
    this.displayImport = false;
    this.cdr.detectChanges();
  }

  remit() {
    if (this.selectedClaims) {
      let totalPayments = 0.0;
      let totalPaymentsStr = '0.00';
      this.selectedClaims.forEach((element) => {
        const approvedAmount = element.approvedAmount ? element.approvedAmount : 0.0;
        totalPayments += parseFloat(approvedAmount);
      });
      if (totalPayments && totalPayments > 0) {
        totalPaymentsStr = (Math.round(totalPayments * 100) / 100).toFixed(2);
      }
      this.form.patchValue({
        claimSelected: this.selectedClaims && this.selectedClaims.length,
        totalPayments: totalPaymentsStr,
      });
      this.displayImport = true;
      this.cdr.detectChanges();
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select atleast on claim.',
      });
    }
  }

  private getPayload(NoOfClaimsSelected) {
    this.searchParams = this.searchParams && typeof this.searchParams !== 'string'
      ? this.searchParams
      : {
        status: { key: 'APPROVED FOR PAYMENT', value: 'APPROVED FOR PAYMENT' }
      };

    this.searchParams.limit = NoOfClaimsSelected;
    return this.searchParams;
  }

  getClaimItemsCount() {
    this.isLoading = true;
    this.querySubscription = this.claimService
      .getBulkClaimProcessItemsCount(this.getPayload(null)) //passing null to fetch maximum records available for the filter applied
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const item = data.getBulkClaimProcessItems;
          if (!item) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to retrieve the records count',
            });
            return;
          }

          this.MaxClaimsForBulkProcess = item.totalClaims;

          this.formModal.patchValue({
            maxNoOfClaimsForBulkProcess: item.totalClaims,
            totalApprovedAmountForBulkProcess: parseFloat(item.totalApprovedAmount).toFixed(2)
          });
          this.displayBulkClaimProcess = true;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  getClaimProcessSearchResult() {
    this.isLoading = true;

    this.searchParams =
      this.searchParams && typeof this.searchParams !== 'string'
        ? this.searchParams
        : {
          limit: this.offsetLimit,
          offset: 0,
          status: { key: 'APPROVED FOR PAYMENT', value: 'APPROVED FOR PAYMENT' },
        };

    this.searchParams.limit = this.offsetLimit;
    this.searchParams.offset = this.lastOffsetVal;

    this.lastOffsetVal += this.offsetLimit;

    this.querySubscription = this.claimService
      .getClaimProcessSearchResult(this.searchParams)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const rawModifedData = copy(data.getClaimProcessSearchResults);
          // Perform field formatting
          const modifedData = [];
          for (const singleRow of rawModifedData) {
            singleRow.productPurchaseDate = generalDateFormatter(singleRow.productPurchaseDate);
            singleRow.failureDate = generalDateFormatter(singleRow.failureDate);
            singleRow.failureReportedDate = generalDateFormatter(singleRow.failureReportedDate);
            singleRow.planDateofPurchase = generalDateFormatter(singleRow.planDateofPurchase);
            singleRow.approvedForPaymentDate = generalDateFormatter(
              singleRow.approvedForPaymentDate,
            );
            singleRow.dateOfInvoice = generalDateFormatter(singleRow.dateOfInvoice);
            singleRow.dateOfRepair = generalDateFormatter(singleRow.dateOfRepair);
            modifedData.push(singleRow);
          }
          this.disableLoadMore =
            Boolean(modifedData.length < this.offsetLimit) || !Boolean(modifedData.length);
          this.dataList = !!this.dataList ? [...this.dataList, ...modifedData] : [...modifedData];
          this.totalRecords = this.dataList.length;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  onBulkProcessSubmit() {
    const claimProcessDetails = this.formModal.getRawValue();
    if (!claimProcessDetails.NoOfClaimsForBulkProcess || isNaN(parseInt(claimProcessDetails.NoOfClaimsForBulkProcess.trim()))) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter valid number',
      });
      return;
    }

    if (claimProcessDetails.NoOfClaimsForBulkProcess > this.MaxClaimsForBulkProcess) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Entered value should be equal or less than the claim records',
      });
      return;
    }

    this.isLoading = true;
    this.querySubscription = this.claimService
      .getBulkClaimProcessItemsCount(this.getPayload(claimProcessDetails.NoOfClaimsForBulkProcess))
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const item = data.getBulkClaimProcessItems;
          if (!item) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to retrieve the records count',
            });
            return;
          }

          this.formConfirmBulkProcessGroup.patchValue({
            NoOfClaimsForBulkProcess: item.totalClaims,
            totalPaymentsForBulkProcess: parseFloat(item.totalApprovedAmount).toFixed(2)
          });

          this.displayConfirmBulkClaimProcess = true;
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  exportToCsv(dt) {
    const orginalSelectedColumns = this.selectedColumns;
    this.selectedColumns = this.cols;
    this.cdr.detectChanges();
    dt.exportToCsv({ selectionOnly: true });
    this.selectedColumns = orginalSelectedColumns;
    this.cdr.detectChanges();
    this.displayImport = false;
  }

  exportToCSV() {

    //Converting the array properties to the desired name
    const records = this.selectedClaims.map(item => {
      const returnValue = {}
      this.cols.forEach(col => {
        returnValue[col.header] = item[col.field]
      })
      return returnValue;
    })

    this.exportService.exportToCsv(records,'claims');
  }

  exportRecords() {
    const details = this.formConfirmBulkProcessGroup.getRawValue();
    const claimProcessRequest = this.searchParams;
    claimProcessRequest.status = this.searchParams.status?.value;
    claimProcessRequest.paymentType = this.searchParams.paymentType?.value;
    const requestPayload = {
      exportRequestsType: 'ProcessClaims',
      insertUserName: this.azureService.accountId,
      requestInfo: JSON.stringify({
        totalRecords: details.NoOfClaimsForBulkProcess,
        totalAmount: details.totalPaymentsForBulkProcess,
        claimProcessPayload : claimProcessRequest
      })
    };
    this.displayBulkClaimProcess = false;
    this.displayConfirmBulkClaimProcess = false;
    this.isLoading = true;
    this.querySubscription =
      this.insuranceService.addExportRequest(requestPayload).subscribe(
        ({ data }) => {
          if (data && data.addExportRequest) {
            this.exportRequestsId = data.addExportRequest.exportRequestsIdOriginal;
            this.onFileStatusCheck();
          }
        },
        (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error adding file to export process.',
          });
          this.isLoading = false;
          throw err;
        },
      );
  }

  onFileStatusCheck() {
    this.isLoading = true;
    if (this.exportRequestsId) {
      this.uploadStartTime = moment();
      this.fileStatus = 0;
      this.uploadSubscription = timer(0, this.CHECK_FILE_STATUS_TIMER * 1000)
        .pipe(
          switchMap(() => this.insuranceService.getExportStatus(this.exportRequestsId)),
          takeWhile(() => {
            const res = this.checkMaxTimeOut() && (!this.fileStatus || this.checkFileStatusCall());
            return res;
          }),
        )
        .subscribe(
          ({ data }) => {
            const results = data.getExportStatus;
            this.fileStatus = results.requestStatus;
            if (results.requestStatus === 1) {
              this.isLoading = false;
              const preSignedUrl = results.fileName;
              this.createFile(preSignedUrl);
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'File is generated successfully.',
              });
            } else if (results.requestStatus === 2 || results.requestStatus === 3) {
              this.isLoading = false;
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Error generating file.',
              });
            }
          },
          (err) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error retrieving file generation status.',
            });
            this.isLoading = false;
            throw err;
          },
        );
    }
  }

  createFile(preSignedUrl) {
    this.viewFileService.getFile(preSignedUrl).subscribe(
      (response) => {
        const momentDate = moment(new Date());
        const momentDateStr = momentDate.format('MMM D YYYY HHmmss');
        const fileName = decodeURIComponent(`${momentDateStr}_Claims.csv`);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response);
        link.download = fileName;
        link.click();
        this.isLoading = false;
      },
      (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error generating file.',
        });
        this.isLoading = false;
      },
    );
  }

  checkMaxTimeOut() {
    const timeDiffSec = moment.duration(moment().diff(this.uploadStartTime)).asSeconds();
    const diffRes = timeDiffSec < this.MAX_TIMEOUT;
    const errMsg =
      'File is still being generated. If you have multiple files, try less files and regenerate.';
    if (!diffRes) {
      this.showConfirmationMsg('File Generate Status', errMsg);
      this.isLoading = false;
    }
    return diffRes;
  }

  showConfirmationMsg(header, message) {
    this.confirmationService.confirm({
      header,
      message,
      rejectVisible: false,
      acceptLabel: 'OK',
    });
    this.isLoading = false;
  }

  checkFileStatusCall() {
    if (this.fileStatus === 1) {
      return false;
    }
    if (this.fileStatus === 2 || this.fileStatus === 3) {
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
    }
  }
}
