export enum AddressCategoryType {
  Agent,
  ClaimContact,
  ClaimProduct,
  Customer,
  DealersGroup,
  Seller,
  ContractHistory,
  ServicerProfile,
  InsuranceComapany,
  ClaimPayee,
  CoveragePlans,
}

export enum CrmDocumentType {
  Agent,
  ClaimContact,
  ClaimProduct,
  Customer,
  DealersGroup,
  Seller,
  ContractHistory,
  ServicerProfile,
  InsuranceComapany,
  ClaimPayee,
  CancellationUpload,
  ImportDealers,
  ImportDealerLocations,
  ImportFailureTypes,
  ImportServiceRates,
  ImportServicerInsurances,
  ImportServicers,
  ImportContracts,
  ImportCancellations,
  ImportManufacturers,
  ImportPositions,
  ImportCategories,
  ImportSubcategories,
  ImportTiers,
  ImportContractStatusChange,
  ImportOEMWarranties,
  ImportSalesRepresentatives,
  ServicerClaimSubmission,
  CoverageSKUUpload,
  ProductLocation,
  RemittanceRecipient,
  ContractHistoryCustomer,
  Contact,
  ImportPriceTable,
  ServiceArea,
  ServiceType,
  ContractClaimNotes,
  ServicerRates,
  ManufacturerImport,
  OEMImport,
  InsuranceCarrierImport,
  ContractDoc,
}

export enum ModuleType {
  CONTRACT = 'CONTRACT',
  SERVICE = 'SERVICE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  BUYINGGROUP = 'BUYINGGROUP',
  TROUBLESHOOTING = 'TROUBLESHOOTING',
  DEALER = 'DEALER',
  COVERAGE = 'COVERAGE',
  CATALOG = 'CATALOG',
  CUSTOMER = 'CUSTOMER',
  SERVICEPROVIDER = 'SERVICEPROVIDER',
  ADMIN = 'ADMIN',
  FORM = 'FORM',
  DEALERSERVICER = 'DEALERSERVICER',
  SERVICEAREA = 'SERVICEAREA',
  SERVICERINSURANCE = 'SERVICERINSURANCE',
  DEALERLOCATION = 'DEALERLOCATION'
}

export enum NoteType {
  SentEmail = 'Sent Email',
  StatusChange = 'Status Change',
  CreditCardInfo = 'CreditCard Info',
}
