import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ellipsisHeaderOptions } from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { FormFieldControlService } from 'src/app/modules/crm/services/form-field-control.service';
import { FormField } from 'src/app/modules/crm/shared/form-field/form-field';
import { ProblemService } from '../problem.service';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { MapType } from '@angular/compiler';
import {
  dateSubstring,
  getDateDiff,
  getMMDDYYYFormatDate,
  getMMDDYYYFormatWithoutTimeZone,
} from 'src/app/modules/crm/shared/utilities/date-utilities';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';
import { DatePipe } from '@angular/common';

const FIFTYBACK_ALLOW_ROLES = ['Admin', 'Claims Manager', 'Claims 1', 'Claims 1 Plus'];

@Component({
  selector: 'standard-problem',
  templateUrl: 'standard-problem.template.html',
  styleUrls: ['./standard-problem.scss'],
  providers: [FormFieldControlService],
})
export class StandardProblemComponent implements OnInit, OnDestroy {
  formFields: FormField<string>[] = [];
  formFieldsPart1: FormField<string>[] = [];
  form: FormGroup;
  selectedItem: any;
  ellipsisHeaderOptions: any[] = copy(ellipsisHeaderOptions);
  cols: any[];
  colsTs: any[];
  problemInfo: any[];
  problemTs: any[];
  symptomsList: any[];
  failureList: any[];
  selectedFailureValue: any;
  symptomsTableHeaders = [];
  isLoading: boolean;
  productsIdOriginal: number;
  resolveMap = new Map();
  resolutionText: string;
  isProdIdOrigFound = false;
  isMainTabDataAvailable: boolean;
  isClaimDataAvailable: boolean;
  querySubscriptions: Subscription[] = [];

  counterValue = 0;

  get selectedFailure() {
    return this.selectedFailureValue;
  }

  set selectedFailure(value) {
    this.selectedFailureValue = value;
    this.resolutionText = '';
    if (this.isProdIdOrigFound && value.key) {
      this.updateSymptomsList(this.productsIdOriginal, this.selectedFailureValue.key);
    } else if (
      this.isProdIdOrigFound &&
      !value.key &&
      this.failureList &&
      this.failureList.length
    ) {
      this.selectedFailureValue = {
        key: this.failureList[0][`value`],
        value: this.failureList[0][`value`],
      };
      this.updateSymptomsList(this.productsIdOriginal, this.failureList[0][`value`]);
    }
  }

  constructor(
    private qcs: FormFieldControlService,
    private service: ProblemService,
    private serviceOrderDataService: ServiceOrderDataService,
    private messageService: MessageService,
    private azureService: AzureLoginService,
    private datePipe: DatePipe,
  ) {
    this.service.getFailureFields().subscribe((data: FormField<string>[]) => {
      this.formFields = data;
      this.formFieldsPart1 = this.formFields.slice(0, 6);
      this.form = this.qcs.toFormGroup(this.formFields);
    });

    this.failureList = [{ key: 'OTHER', value: 'OTHER' }];

    this.serviceOrderDataService.isMainTabDataAvailable.subscribe({
      next: (isMainTabDataAvailable) => {
        this.isMainTabDataAvailable = isMainTabDataAvailable;
      },
    });
    this.serviceOrderDataService.isClaimDataAvailable.subscribe({
      next: (isClaimDataAvailable) => {
        this.isClaimDataAvailable = isClaimDataAvailable;
        if (this.isClaimDataAvailable && this.isMainTabDataAvailable) {
          if (
            this.serviceOrderDataService.serviceOrderDataModel.productInfoModel &&
            this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.productsIdOriginal
          ) {
            this.isProdIdOrigFound = true;
          }
          if (this.serviceOrderDataService.standardProblemDataModel) {
            const claimDate =
              this.serviceOrderDataService.serviceOrderDataModel.claimData &&
              this.serviceOrderDataService.serviceOrderDataModel.claimData.claimDate;
              this.serviceOrderDataService.standardProblemDataModel.reportedDate = 
              this.serviceOrderDataService.standardProblemDataModel.reportedDate
                  ? this.serviceOrderDataService.standardProblemDataModel.reportedDate
                  : new Date().toISOString();
              this.serviceOrderDataService.standardProblemDataModel.failureDate = 
              this.serviceOrderDataService.standardProblemDataModel.failureDate
              ? this.serviceOrderDataService.standardProblemDataModel.failureDate
              : new Date().toISOString();
            this.form.controls[`failureDate`].setValue(
              this.patchDate(this.serviceOrderDataService.standardProblemDataModel.failureDate)
            );
            this.populateDayDifference(
              this.serviceOrderDataService.standardProblemDataModel.failureDate,
            );
            this.form.controls[`reportedDate`].setValue(
              this.patchDate(this.serviceOrderDataService.standardProblemDataModel.reportedDate)
            );
            this.form.controls[`functionalStatus`].setValue({
              key: this.serviceOrderDataService.standardProblemDataModel.functionalityStatus,
              value: this.serviceOrderDataService.standardProblemDataModel.functionalityStatus,
            });
            this.form.controls[`failureDescription`].setValue(
              this.serviceOrderDataService.standardProblemDataModel.failureDescription,
            );
          }
          if (this.isProdIdOrigFound) {
            this.productsIdOriginal =
              this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.productsIdOriginal;
            this.querySubscriptions.push(
              this.serviceOrderDataService
                .getSymptomByProductTierResult(this.productsIdOriginal)
                .subscribe(
                  ({ data, loading }: any) => {
                    this.isLoading = false;
                    const modifedData = data.getSymptomByProductTier.map((item) => {
                      return { key: item, value: item };
                    });
                    this.failureList = modifedData;
                    if (FIFTYBACK_ALLOW_ROLES.includes(this.azureService.roleName)) {
                      this.failureList.push({ key: '50% Back', value: '50% Back' });
                    }
                    // TODO
                    const problemData = this.serviceOrderDataService.standardProblemDataModel;
                    // const failureDate = getFormattedDate(new Date(this.serviceOrderDataService.standardProblemDataModel.failureDate));
                    // this.form.patchValue({
                    //   failureDate : dateSubstring(this.serviceOrderDataService.standardProblemDataModel.failureDate)
                    // });
                    this.selectedFailure = { key: problemData.symptom, value: problemData.symptom };
                    this.resolutionText = problemData.symptomFixNote;
                  },
                  (err) => {
                    this.isLoading = false;
                    throw err;
                  },
                ),
            );
          }
        }
      },
    });

    this.cols = [
      { field: 'model', header: 'MODEL #' },
      { field: 'ofClaims', header: '# OF CLAIMS' },
      { field: 'sameFailure', header: 'SAME FAILURE' },
    ];

    this.symptomsTableHeaders = ['TROUBLESHOOTING', 'RESOLVED', 'NOT RESOLVED'];

    this.service.getStandardInfo().subscribe((data) => {
      this.problemInfo = data;
    });
    this.service.getTroubleshootInfo().subscribe((data) => {
      this.problemTs = data;
    });

    this.querySubscriptions.push(
      this.serviceOrderDataService.saveStandardFormData.subscribe((data) => {
        if (data) {
          this.updateStandardProblemDataModel();
          const failDesc = this.serviceOrderDataService.standardProblemDataModel.failureDescription;
          this.serviceOrderDataService.validatorDataModel.failureDescription = failDesc;
          this.serviceOrderDataService.isValidatorDataAvailable.next(true);
        }
      }),
    );
  }

  patchDate(date) {
    let finalFormattedDispatchDate = null;
    if (date !== null) {
      const charCount = date.length;
      if(charCount){
      if (date && date.endsWith("Z")) {
        const slicingDate = date.slice(11, -1);
        if(slicingDate === "00:00:00.000"){
        finalFormattedDispatchDate = getMMDDYYYFormatDate(date)
        }
        else{
          finalFormattedDispatchDate = this.datePipe.transform(date, 'MM/dd/yyyy');
        }
      } else {
        finalFormattedDispatchDate = this.datePipe.transform(date, 'MM/dd/yyyy');
      }
    } else {
      finalFormattedDispatchDate = this.datePipe.transform(date, 'MM/dd/yyyy');
    }
  }
    return finalFormattedDispatchDate;
  }
  

  ngOnInit() {
    if (this.isProdIdOrigFound && this.selectedFailureValue) {
      this.updateSymptomsList(this.productsIdOriginal, this.selectedFailureValue.key);
    }
    this.form.get('failureDate').valueChanges.subscribe((x) => {
      this.populateDayDifference(x);
    });
  }

  onSubmit() {
    // functionality for on submit
  }

  updateStandardProblemDataModel() {
    this.mappingProblemData();
    const { failureDate, reportedDate, functionalityStatus, failureDescription, symptom } =
      this.serviceOrderDataService.standardProblemDataModel;
    if (failureDate && reportedDate && functionalityStatus && failureDescription && symptom) {
      this.serviceOrderDataService.saveDataComplete.next(true);
    }
  }
  mappingProblemData() {
    if (!this.selectedFailure) {
      this.selectedFailure = this.failureList[0];
    }
    const formData = this.form.value;
    this.serviceOrderDataService.standardProblemDataModel.currentFailureDate =  this.serviceOrderDataService.standardProblemDataModel.failureDate;
    this.serviceOrderDataService.standardProblemDataModel.currentReportedDate =  this.serviceOrderDataService.standardProblemDataModel.reportedDate;
    this.serviceOrderDataService.standardProblemDataModel.failureDate = formData.failureDate
      ? this.patchDate(formData.failureDate)
      : null;
    this.serviceOrderDataService.standardProblemDataModel.reportedDate = this.form.controls
      .reportedDate.value ? this.patchDate(this.form.controls.reportedDate.value)
      : null;
    this.serviceOrderDataService.standardProblemDataModel.functionalityStatus =
      formData.functionalStatus.value;
    this.serviceOrderDataService.standardProblemDataModel.symptom = this.selectedFailure
      ? this.selectedFailure.key
      : '';
    this.serviceOrderDataService.standardProblemDataModel.symptomFixNote = this.resolutionText;
    this.serviceOrderDataService.standardProblemDataModel.failureDescription =
      formData.failureDescription;

    this.serviceOrderDataService.standardProblemDataModelExtened.mfgDop =
      formData.failureMfgExpiration;
    this.serviceOrderDataService.standardProblemDataModelExtened.planDop = formData.failurePlanDOP;
    this.serviceOrderDataService.standardProblemDataModelExtened.productDop =
      formData.failureProductDOP;
  }
  // onSelectionChange(event) {
  //   this.selectedFailure = event.value.value;
  //   if (this.isProdIdOrigFound) {
  //     this.updateSymptomsList(this.productsIdOriginal, this.selectedFailure);
  //   }
  // }

  updateSymptomsList(productsIdOriginal, failureType) {
    this.isLoading = true;
    this.querySubscriptions.push(
      this.serviceOrderDataService
        .getSymptomOptionsByTierAndSymptomResult(productsIdOriginal, failureType)
        .subscribe(
          ({ data, loading }: any) => {
            this.isLoading = loading;
            const modifedData = data.getSymptomOptionsByTierAndSymptom;
            this.symptomsList = modifedData;
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        ),
    );
  }

  resolutionSelection(rowNum, resolvedStatus) {
    this.resolveMap.set(rowNum, resolvedStatus);
    this.resolutionText = this.symptomsList[this.getLastResolvedRow()];
    this.resolutionText = this.resolutionText ? this.resolutionText : '';
  }

  getLastResolvedRow() {
    const validRow = [];
    this.resolveMap.forEach((v, k) => {
      if (v === 1) {
        validRow.push(k);
      }
    });
    validRow.sort((a, b) => {
      return b - a;
    });
    return validRow[0];
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  populateDayDifference(failureDate) {
    if (
      this.serviceOrderDataService.serviceOrderDataModel.productInfoModel &&
      this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.productsIdOriginal
    ) {
      const failureProductDOP = getDateDiff(
        failureDate,
        this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.productPurchaseDate,
        'days',
      );
      this.form.controls[`failureProductDOP`].setValue(failureProductDOP);

      const failurePlanDOP = getDateDiff(
        failureDate,
        this.serviceOrderDataService.serviceOrderDataModel.productInfoModel.saleDate,
        'days',
      );
      this.form.controls[`failurePlanDOP`].setValue(failurePlanDOP);

      if (this.serviceOrderDataService.serviceOrderDataModel.coverageDetails[0]) {
        const failureMfgExpiration = getDateDiff(
          failureDate,
          this.serviceOrderDataService.serviceOrderDataModel.coverageDetails[0].coverageExpDate,
          'days',
        );
        this.form.controls[`failureMfgExpiration`].setValue(failureMfgExpiration);
      }
    }
  }

  onChange(event, type) {
    if (!event.target) {
      const claimDate =
        this.serviceOrderDataService.serviceOrderDataModel.claimData &&
        this.serviceOrderDataService.serviceOrderDataModel.claimData.claimDate;
      const reportedDate = this.patchDate(this.form.controls.reportedDate.value);
      const failureDate = this.patchDate(this.form.controls.failureDate.value);
      if (claimDate) {
        // Update problem
        switch (type) {
          case 'failureDate':
            if (
              getDateDiff(failureDate, reportedDate, 'days') <= 0 &&
              getDateDiff(failureDate, claimDate, 'days') <= 0 &&
              getDateDiff(failureDate, new Date(), 'days') <= 0
            ) {
              this.mappingProblemData();
            } else {
              this.clearFailureReportedDates('failureDate');
            }
            break;
          case 'reportedDate':
            if (
              getDateDiff(reportedDate, failureDate, 'days') >= 0 &&
              getDateDiff(reportedDate, claimDate, 'days') <= 0 &&
              getDateDiff(reportedDate, new Date(), 'days') <= 0
            ) {
              this.mappingProblemData();
            } else {
              this.clearFailureReportedDates('reportedDate');
            }
            break;
          default:
            break;
        }
      } else {
        // Add problem
        switch (type) {
          case 'failureDate':
            if (reportedDate) {
              if (
                getDateDiff(failureDate, reportedDate, 'days') <= 0 &&
                getDateDiff(failureDate, new Date(), 'days') <= 0
              ) {
                this.mappingProblemData();
              } else {
                this.clearFailureReportedDates('failureDate');
              }
            } else {
              if (getDateDiff(failureDate, new Date(), 'days') <= 0) {
                this.mappingProblemData();
              } else {
                this.clearFailureReportedDates('failureDate');
              }
            }
            break;
          case 'reportedDate':
            if (failureDate) {
              if (
                getDateDiff(reportedDate, failureDate, 'days') >= 0 &&
                getDateDiff(reportedDate, new Date(), 'days') <= 0
              ) {
                this.mappingProblemData();
              } else {
                this.clearFailureReportedDates('reportedDate');
              }
            } else {
              if (getDateDiff(reportedDate, new Date(), 'days') <= 0) {
                this.mappingProblemData();
              } else {
                this.clearFailureReportedDates('reportedDate');
              }
            }
            break;
          default:
            break;
        }
      }
    }
  }

  clearFailureReportedDates(type) {
    this.form.controls[type].setValue(null);
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Please fill valid dates',
    });
  }
  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
