import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ContractService } from '../contract.service';
import { TabHandlerService } from '../../services/tab-handler.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { soClaimHeaderOptions, ellipsisRowOptions } from './so-claim.ellipsis';
import { TabsService } from '../../services/tabs.service';
import { ServiceOrderDataService } from '../../service/service-order/service-order-data.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { ConfirmationService } from 'primeng/api';
import { CrmService } from '../../services/crm-service';
import { ModuleType } from '../../shared/constants/enums';
import { dateFormatterCST, generalDateFormatter } from '../../shared/utilities/date-utilities';
import { copy } from '../../shared/utilities/common-utilities';
import moment from 'moment';

@Component({
  selector: 'so-claims-tab',
  templateUrl: 'so-claims-tab.template.html',
  styleUrls: ['so-claims-tab.scss'],
  providers: [ServiceOrderDataService],
})
export class SoClaimsTabComponent implements OnInit, OnDestroy {
  admin: any[];
  cols: any[];
  selectedColumns: any[];
  tabKey: string;
  querySubscription: any;
  isLoading: boolean;
  selectedItem: any;
  selectedRowIndex: number;
  ellipsisHeaderOptions: any[] = soClaimHeaderOptions;
  ellipsisOptions: any[] = ellipsisRowOptions;
  displayDialog: boolean;
  dialogDataList: any[];
  dialogCols: any[];
  querySubscriptionModel: any;
  isDialogLoading: boolean;
  claimType: { label: string; value: string }[];
  claimTypeInfo: { label: string; value: string }[];
  displayLolDialog: boolean;
  isDialogLolLoading: boolean;
  dialogLolCols: any[];
  dialogLolDataList = [];
  isAddClaimLoading: boolean;
  displayPpp = false;
  pppPopupDescription = '';
  coverageInformation: any;
  authDetails: IRoleAuthorizationService;
  private buttons: any;
  buttonAuth: any = {};
  serviceOrderParams: { id: any; customerId: any; data: any };
  enableAddClaim = true;
  effectiveDateChange: string;
  isEditing = false;
  constructor(
    private contractService: ContractService,
    private tabHandlerService: TabHandlerService,
    private tabService: TabsService,
    private serviceOrderDataService: ServiceOrderDataService,
    private roleService: RoleAuthorizationService,
    private cdr: ChangeDetectorRef,
    public azureService: AzureLoginService,
    private confirmationService: ConfirmationService,
    private crmService: CrmService,
  ) {}

  ngOnInit() {
    this.cols = [
      { field: 'serviceOrderNumber', header: 'SERVICE ORDER #' },
      { field: 'claimStatusDescription', header: 'STATUS' },
      { field: 'approvedAmount', header: 'APPROVED AMOUNT' },
      { field: 'paymentAmount', header: 'PAYMENT AMOUNT' },
      { field: 'subCategory', header: 'SUB CATEGORY' },
      { field: 'modelNumber', header: 'MODEL#' },
      //{ field: 'claimPriorityDescription', header: 'PRIORITY' },
      { field: 'claimFollowUpDate', header: 'FOLLOW UP DATE' },
      { field: 'actionRequired', header: 'Action Required' },
      { field: 'claimDate', header: 'CREATED DATE' },
      { field: 'symptom', header: 'FAILURE' },
      { field: 'dateOfRepair', header: 'REPAIR DATE' },
      { field: 'correctiveAction', header: 'CORRECTIVE ACTION' },
      { field: 'claimNumber', header: 'CLAIM #' },
      { field: 'serviceProvider', header: 'SERVICE PROVIDER' },
    ];

    this.dialogCols = [
      { field: 'productsId', header: 'PROD#', type: 'text' },
      { field: 'modelName', header: 'MODEL#', type: 'text' },
      { field: 'subcategoryDescription', header: 'SUB CAT.', type: 'text' },
      { field: 'productPurchasePrice', header: 'PPP', type: 'text' },
      { field: 'limitOfLiability', header: 'LOL', type: 'text' },
      { field: 'serialNumber', header: 'SERIAL#', type: 'text' },
      { field: 'fulfilled', header: 'FULFILLED', type: 'text' },
    ];

    this.dialogLolCols = [
      { field: 'productsId', header: 'PROD#', type: 'text' },
      { field: 'manufacturer', header: 'MFW', type: 'text' },
      { field: 'modelName', header: 'MODEL NAME', type: 'text' },
      { field: 'modelNumber', header: 'MODEL#', type: 'text' },
      { field: 'serialNumber', header: 'SERIAL#', type: 'text' },
      { field: 'productPurchaseDate', header: 'PPD', type: 'text' },
      { field: 'productPurchasePrice', header: 'PPP', type: 'text' },
      { field: 'liabilitySpent', header: 'LIABILITY SPENT', type: 'text' },
      { field: 'liabilityRemaining', header: 'LIABILITY REMAINING', type: 'text' },
      { field: 'totalLiability', header: 'TOTAL LIABILTY', type: 'text' },
    ];
    this.contractService.isEffectiveDateUpdated.subscribe((data) => {
      this.effectiveDateChange = data;
    });
    // Button Role Authorization
    this.buttons = [{ name: 'Add Claim' }];
    this.buttonAuth = {
      'Add Claim': false,
    };
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: SoClaimsTabComponent,
      generalArray: this.buttons,
    };
    this.buttons = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    for (const singleButton of this.buttons) {
      this.buttonAuth[singleButton.name] = true;
    }

    this.selectedColumns = this.cols.slice(0, 9);
    const enabledForActive = this.roleService.validateIfPermissionsExist({
      category: 'Claim',
      permission: 'ADD Claim on Active Contract',
      operation: 'ADD',
      type: 'Code',
    });
    const enabledForPending = this.roleService.validateIfPermissionsExist({
      category: 'Claim',
      permission: 'ADD Claim on Pending Contract',
      operation: 'ADD',
      type: 'Code',
    });
    const enabledForExpired = this.roleService.validateIfPermissionsExist({
      category: 'Claim',
      permission: 'ADD Claim on Expired Contract',
      operation: 'ADD',
      type: 'Code',
    });
    const enabledForSuspended = this.roleService.validateIfPermissionsExist({
      category: 'Claim',
      permission: 'ADD Claim on Suspended Contract',
      operation: 'ADD',
      type: 'Code',
    });
    const enabledForUnprocessed = this.roleService.validateIfPermissionsExist({
      category: 'Claim',
      permission: 'ADD Claim on Unprocessed Contract',
      operation: 'ADD',
      type: 'Code',
    });
    const enabledForVoid = this.roleService.validateIfPermissionsExist({
      category: 'Claim',
      permission: 'ADD Claim on Void Contract',
      operation: 'ADD',
      type: 'Code',
    });
    const enabledForCancelled = this.roleService.validateIfPermissionsExist({
      category: 'Claim',
      permission: 'ADD Claim on Cancelled Contract',
      operation: 'ADD',
      type: 'Code',
    });
    // get data
    this.tabHandlerService.selectedTab.subscribe({
      next: (tab) => {
        if (this.tabKey === tab) {
          // Not able to add a claim that is not Active, unless if Admin, which can also create in Pending.
          if (
            this.contractService &&
            this.contractService.contractDataModel &&
            this.contractService.contractDataModel.leftBaseSection &&
            this.contractService.contractDataModel.leftBaseSection.contractStatusDescription
          ) {
            const contractStatusDescription =
              this.contractService.contractDataModel.leftBaseSection.contractStatusDescription;
            switch (contractStatusDescription) {
              case 'Active':
                this.enableAddClaim = enabledForActive;
                break;
              case 'Pending':
                this.enableAddClaim = enabledForPending;
                break;
              case 'Suspended':
                this.enableAddClaim = enabledForSuspended;
                break;
              case 'Unprocessed':
                this.enableAddClaim = enabledForUnprocessed;
                break;
              case 'Void':
                this.enableAddClaim = enabledForVoid;
                break;
              case 'Expired':
                this.enableAddClaim = enabledForExpired;
                break;
              case 'Cancelled':
                this.enableAddClaim = enabledForCancelled;
                break;
            }

            if (this.azureService.roleName !== 'Admin') {
              this.ellipsisOptions = this.ellipsisOptions.filter((x) => x.value !== 'Delete');
            }
          }
          const today = new Date();
          let contractEffectiveDate = this.contractService.contractDataModel.leftBaseSection
            .effectiveDate
            ? this.contractService.contractDataModel.leftBaseSection.effectiveDate
            : today;
          if (this.effectiveDateChange !== '') {
            contractEffectiveDate = this.effectiveDateChange;
          }
          if (moment(today) <= moment(contractEffectiveDate)) {
            this.enableAddClaim = false;
          }
          this.getSOClaims();
        }
      },
    });
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  openTab(data: any) {
    this.tabService.createTab({
      header: `Service Order [ ${data.serviceOrderNumber} ]`,
      content: {
        claimId: data.claimIdOriginal,
        contractId:
          this.contractService.contractDataModel.leftBaseSection &&
          this.contractService.contractDataModel.leftBaseSection.contractsIdOriginal,
      },
      type: 'Service Order',
    });
  }

  onClickAdd(event, overlaypanel: OverlayPanel) {
    if (
      this.contractService.contractDataModel.leftBaseSection.contractStatusDescription.toUpperCase() ===
      'EXPIRED'
    ) {
      this.confirmationService.confirm({
        header: `Expired Contract Message`,
        message: `Please review the Effective Start Date on this Contract.`,
        acceptLabel: 'Proceed',
        rejectLabel: 'Cancel',
        accept: () => {
          this.displayCoveredProducts(overlaypanel);
        },
        reject: () => {
          return;
        },
      });
    } else {
      this.displayCoveredProducts(overlaypanel);
    }
  }

  onRowEdit(event, overlaypanel: OverlayPanel) {
    this.tabService.createTab({
      header: 'Service Order',
      content: {
        claimId: this.selectedItem.claimIdOriginal,
        contractId:
          this.contractService.contractDataModel.leftBaseSection &&
          this.contractService.contractDataModel.leftBaseSection.contractsIdOriginal,
      },
      type: 'Service Order',
    });
    overlaypanel.hide();
  }

  onRowDelete(event, overlaypanel: OverlayPanel) {
    const payload = {
      idOriginal: this.selectedItem.claimIdOriginal,
      moduleName: ModuleType.SERVICEPROVIDER,
      azureUserId: this.azureService.accountId,
    };
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this claim ?',
      accept: () => {
        this.isLoading = true;
        this.crmService.deleteCrmModule(payload).subscribe(
          ({ data, loading }: any) => {
            this.isLoading = false;
            if (!data.deleteCrmModule) {
              throw new Error('Error in deleting the servicer.');
            }
            this.isLoading = true;
            this.getSOClaims();
          },
          (err) => {
            this.isLoading = false;
            throw err;
          },
        );
      },
    });
    overlaypanel.hide();
  }

  onRowCreateSubClaim(event, overlaypanel: OverlayPanel) {
    if (
      this.contractService.contractDataModel.leftBaseSection.contractStatusDescription.toUpperCase() ===
      'EXPIRED'
    ) {
      this.confirmationService.confirm({
        header: `Expired Contract Message`,
        message: `Please review the Effective Start Date on this Contract.`,
        acceptLabel: 'Proceed',
        rejectLabel: 'Cancel',
        accept: () => {
          this.createSubClaim(overlaypanel);
        },
        reject: () => {
          return;
        },
      });
    } else {
      this.createSubClaim(overlaypanel);
    }
  }

  addNewClaim(rowData: any, index: number) {
    this.serviceOrderParams = this.contractService.contractConfig.searchParams;
    this.serviceOrderParams.data = {
      coveredProduct: rowData,
      address: this.contractService.contractDataModel.mainTab.addresses,
      customerInformation: this.contractService.contractDataModel.mainTab.customerInformation,
      contractData: this.contractService.contractDataModel.leftBaseSection,
      coverageInformation: this.contractService.contractDataModel.coverageInformation,
    };
    let productPurchasePriceNumber = 0.0;
    if (rowData.productPurchasePrice && rowData.productPurchasePrice !== '') {
      productPurchasePriceNumber = Number(rowData.productPurchasePrice.replace(/[^0-9\.]+/g, ''));
    }
    if (productPurchasePriceNumber === 0) {
      const productsIdOriginal = rowData.productsIdOriginal;
      this.isAddClaimLoading = true;
      this.serviceOrderDataService
        .getRealLimitOfLiabilityResult(productsIdOriginal)
        .subscribe(({ data, loading }: any) => {
          this.isAddClaimLoading = false;
          const popupFlag = data.getRealLimitOfLiabilityById.popupFlag;
          const popupDescription = data.getRealLimitOfLiabilityById.popupDescription;
          this.pppPopupDescription = popupDescription;
          if (popupFlag) {
            this.displayPpp = true;
            this.cdr.detectChanges();
          }
        });
    } else {
      this.tabService.createTab({
        header: 'Service Order',
        content: this.serviceOrderParams,
        type: 'Service Order',
      });
    }
    this.displayDialog = false;
  }

  ellipsisOptionClick(event, overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    // ellipsis functionality goes here
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    if (this.querySubscriptionModel) {
      this.querySubscriptionModel.unsubscribe();
    }
  }

  onClickLol(event) {
    this.dialogLolDataList = [];
    const coveredProducts = this.contractService.contractDataModel.mainTab.coveredProducts;
    if (coveredProducts) {
      for (const singleCoveredProduct of coveredProducts) {
        let productPurchaseDate = '';
        if (
          singleCoveredProduct.productPurchaseDate &&
          singleCoveredProduct.productPurchaseDate !== ''
        ) {
          productPurchaseDate = generalDateFormatter(singleCoveredProduct.productPurchaseDate);
        }
        this.dialogLolDataList.push({
          productsId: singleCoveredProduct.productsId,
          manufacturer: singleCoveredProduct.manufacturerManufacturer,
          modelName: singleCoveredProduct.modelNumber,
          modelNumber: singleCoveredProduct.modelNumber,
          serialNumber: singleCoveredProduct.serialNumber,
          productPurchaseDate,
          productPurchasePrice: singleCoveredProduct.productPurchasePrice,
          liabilitySpent: `$${
            singleCoveredProduct.lolVirtualCalculatedFields.liabilitySpent
              ? singleCoveredProduct.lolVirtualCalculatedFields.liabilitySpent.toFixed(2)
              : '0.00'
          }`,
          liabilityRemaining: `$${
            singleCoveredProduct.lolVirtualCalculatedFields.liabilityRemaining
              ? singleCoveredProduct.lolVirtualCalculatedFields.liabilityRemaining.toFixed(2)
              : '0.00'
          }`,
          totalLiability: `$${
            singleCoveredProduct.lolVirtualCalculatedFields.totalLiability
              ? singleCoveredProduct.lolVirtualCalculatedFields.totalLiability.toFixed(2)
              : '0.00'
          }`,
        });
      }
    }
    this.displayLolDialog = true;
  }

  getSOClaims() {
    this.isLoading = true;
    this.querySubscription = this.contractService
      .getSoClaims(this.contractService.contractConfig.searchParams.id)
      .subscribe(
        ({ data, loading }: any) => {
          this.isLoading = loading;
          const modifiedData = copy(data.getContractProfileById);
          if (modifiedData && modifiedData.soClaims) {
            const tableData = [];
            for (const singleSoClaim of modifiedData.soClaims) {
              singleSoClaim.correctiveAction = singleSoClaim.claimServicer
                ? singleSoClaim.claimServicer[0].correctiveAction
                : '';
              singleSoClaim.serviceProvider = singleSoClaim.servicer
                ? singleSoClaim.servicer.servicerName
                : '';
              singleSoClaim.symptom = singleSoClaim.problem ? singleSoClaim.problem.symptom : '';
              singleSoClaim.dateOfRepair = singleSoClaim.techinfo
                ? singleSoClaim.techinfo.dateOfRepair
                : '';
              singleSoClaim.approvedAmount = `$${
                singleSoClaim.claimAmount
                  ? singleSoClaim.claimAmount.approvedAmount.toFixed(2)
                  : '0.00'
              }`;
              singleSoClaim.paymentAmount = `$${
                singleSoClaim.claimAmount
                  ? singleSoClaim.claimAmount.paymentAmount.toFixed(2)
                  : '0.00'
              }`;
              singleSoClaim.modelNumber = singleSoClaim.coveredProduct
                ? singleSoClaim.coveredProduct.modelNumber
                : '';
              singleSoClaim.subCategory = singleSoClaim.coveredProduct
                ? singleSoClaim.coveredProduct.subcategoryDescription
                : '';
              if (!singleSoClaim.serviceOrderNumber) {
                singleSoClaim.serviceOrderNumber = singleSoClaim.claimNumber;
              }

              singleSoClaim.claimDate = dateFormatterCST(singleSoClaim.claimDate);
              tableData.push(singleSoClaim);
            }
            this.admin = tableData;
          }
        },
        (err) => {
          this.isLoading = false;
          throw err;
        },
      );
  }

  closeDialog() {
    this.displayPpp = false;
    this.tabService.createTab({
      header: 'Service Order',
      content: this.serviceOrderParams,
      type: 'Service Order',
    });
  }

  displayCoveredProducts(overlaypanel) {
    if (this.contractService.contractDataModel.mainTab.coveredProducts) {
      this.dialogDataList = this.contractService.contractDataModel.mainTab.coveredProducts;
    } else {
      this.isDialogLoading = true;
      this.querySubscriptionModel = this.contractService
        .getCoveredProducts(this.contractService.contractConfig.searchParams)
        .subscribe(({ data, loading }: any) => {
          const modifiedData = data.getContractProfileById;
          if (modifiedData && modifiedData.coveredProducts) {
            this.dialogDataList = modifiedData.coveredProducts;
          }
          this.isDialogLoading = false;
        });
    }
    this.displayDialog = true;
    overlaypanel.hide();
  }

  createSubClaim(overlaypanel) {
    if (!this.selectedItem.serviceOrderNumber.includes('-1')) {
      window.alert(
        'Only Parent Service Orders can create Sub Claim. Choose the one with Suffix -1',
      );
      return;
    }
    this.tabService.createTab({
      header: 'Service Order',
      content: {
        subClaimFlag: true,
        claimId: this.selectedItem.claimIdOriginal,
        contractId:
          this.contractService.contractDataModel.leftBaseSection &&
          this.contractService.contractDataModel.leftBaseSection.contractsIdOriginal,
      },
      type: 'Service Order',
    });
    overlaypanel.hide();
  }
}
