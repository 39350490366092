import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormFieldControlService } from '../../../../services/form-field-control.service';
import { FormField } from '../../../../shared/form-field/form-field';
import { FormCanDeactivate } from 'src/app/modules/crm/shared/form-field/form-can-deactivate';
import { GeneratePreAuthService } from './generate-pre-auth.service';
import { Subscription } from 'rxjs';
import { ServiceOrderDataService } from '../../service-order-data.service';
import {
  IRoleAuthorizationService,
  AuthorizationType,
} from 'src/app/interfaces/role-authorization.interface';
import { RoleAuthorizationService } from 'src/app/services/role-authorization.service';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import {
  ellipsisCrudOptions,
  ellipsisSubHeaderOptions,
} from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { KeyMap } from 'src/app/modules/crm/shared/interface/key-map.interface';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { v4 as uuidv4 } from 'uuid';
import { MessageService } from 'primeng/api';
import { generalDateFormatter } from 'src/app/modules/crm/shared/utilities/date-utilities';
import { DatePipe } from '@angular/common';
import { ServiceOrderService } from '../../service-order.service';

@Component({
  selector: 'generate-pre-auth',
  templateUrl: 'generate-pre-auth.template.html',
  styleUrls: ['generate-pre-auth.scss'],
  providers: [FormFieldControlService, GeneratePreAuthService, MessageService],
})
export class GeneratePreAuthComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Input() formFields: FormField<any>[] = [];
  form: FormGroup;
  payLoad = '';
  disableRequest = true;
  disableAuthorize = true;
  querySubscriptions: Subscription[] = [];
  isLoading: boolean;
  servicerInfoCache = [];
  private currentUser: any;
  private authDetails: IRoleAuthorizationService;
  private buttons: any;
  buttonAuth: any = {};
  dataList: any[];
  cols: any[];
  selectedItem: any;
  // Need a copy of the objects within the array so we do not update those by reference for other uses.
  ellipsisOptions: any[] = ellipsisCrudOptions.map((object) => ({ ...object }));
  ellipsisHeaderOptions: any[] = ellipsisSubHeaderOptions.map((object) => ({ ...object }));
  selectedEllipsisItem: any;
  clonedRowData: KeyMap = {};
  isEditing: boolean;
  editConstant: string;
  dropdown: any;
  totalRequestedAmount: any;
  totalAdjustedAmount: any;
  totalDiscount: any;
  totalApprovedAmount: any;
  @ViewChild(Table) table: Table;
  displayPreAuthDialog: boolean;
  accountId: any;
  isEditEnable = true;
  isClicked=false;
  constructor(
    private qcs: FormFieldControlService,
    private generatePreAuthService: GeneratePreAuthService,
    private serviceOrderDataService: ServiceOrderDataService,
    private roleService: RoleAuthorizationService,
    private azureService: AzureLoginService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private serviceOrderService: ServiceOrderService
  ) {
    super();
  }

  ngOnInit() {
    this.editConstant = uuidv4();
    this.dataList = [];

    this.querySubscriptions.push(
      this.serviceOrderDataService.isServiceOrderInfoAvailable.subscribe({
        next: (isServiceOrderInfoAvailable) => {
          if (
            isServiceOrderInfoAvailable &&
            this.serviceOrderDataService.serviceOrderDataModel.claimData.disableClaimSave
          ) {
            this.ellipsisHeaderOptions
              .filter((c) => c.label === 'Add New Row')
              .forEach((x) => (x.disabled = true));
          }
        },
      }),
    );
    // If the claim is in Submit for Payment, Approved for Payment or Approved Paid state,
    // No user can edit the details anymore(PD-907057)
    this.serviceOrderDataService.isServiceOrderEditEnable.subscribe((isEnable) => {
      this.isEditEnable = isEnable;
      this.ellipsisHeaderOptions
        .filter((c) => c.label === 'Add New Row')
        .forEach((x) => (x.disabled = !this.isEditEnable));
    });
    this.querySubscriptions.push(
      this.serviceOrderDataService.isClaimDataAvailable.subscribe({
        next: (isClaimDataAvailable) => {
          if (
            isClaimDataAvailable &&
            this.serviceOrderDataService.serviceOrderDataModel.claimData &&
            this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal &&
            !this.serviceOrderDataService.serviceConfig.searchParams.subClaimFlag
          ) {
            const claimId =
              this.serviceOrderDataService.serviceOrderDataModel.claimData.claimIdOriginal;
            let searchKey =
              this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceOrderNumber;
            if (!searchKey) {
              searchKey = claimId ? claimId.toString() : '';
            }
            this.querySubscriptions.push(
              this.generatePreAuthService.getPreAuthDetails(searchKey).subscribe(
                ({ data, loading }) => {
                  this.isLoading = false;
                  const tableData = [];
                  for (const preAuthData of data.getExpensePreAuthDetails) {
                    const singlePreAuth = { ...preAuthData };
                    if (!this.serviceOrderDataService.validatorDataModel.preAuth) {
                      this.serviceOrderDataService.validatorDataModel = Object.assign(
                        {},
                        this.serviceOrderDataService.validatorDataModel,
                        { preAuth: singlePreAuth.authorizationCode },
                      );
                    }
                    if (singlePreAuth.addedTimeStamp && singlePreAuth.addedTimeStamp !== '') {
                      singlePreAuth.addedTimeStampFormatted = this.datePipe.transform(
                        singlePreAuth.addedTimeStamp,'MM/dd/yyyy'
                      );
                    }
                    if (singlePreAuth.approvedAmount && singlePreAuth.approvedAmount !== '') {
                      singlePreAuth.approvedAmountFormatted = `$${singlePreAuth.approvedAmount.toFixed(
                        2,
                      )}`;
                    }
                    tableData.push(singlePreAuth);
                  }
                  this.dataList = tableData;
                  this.serviceOrderDataService.serviceOrderDataModel.preAuthInfo = tableData;
                },
                (err) => {
                  this.isLoading = false;
                  window.alert(
                    'Get Expense Pre-Auth Retrieval Failed. Please try again in a few minutes.',
                  );
                },
              ),
            );
          }
        },
      }),
    );
    this.cols = [
      { field: 'userName', header: 'APPROVED USER', type: 'text', width: '20%' },
      { field: 'addedTimeStampFormatted', header: 'APPROVAL DATE', type: 'text', width: '20%' },
      { field: 'approvedAmountFormatted', header: 'APPROVED AMOUNT', type: 'text', width: '15%' },
      { field: 'authorizationCode', header: 'AUTHORIZATION CODE', type: 'text', width: '20%' },
      { field: 'preAuthNotes', header: 'NOTE', type: 'text', width: '25%' },
    ];
    this.querySubscriptions.push(
      this.azureService.getFullName().subscribe((currentUser: any) => {
        this.currentUser = currentUser;
      }),
    );
    this.accountId = this.azureService.accountId;
    this.buttons = [{ name: 'Authorize' }];
    this.authDetails = {
      authorizationType: AuthorizationType.Button,
      component: GeneratePreAuthComponent,
      generalArray: this.buttons,
    };

    this.buttons = Object.assign(this.roleService.applyAuthorization(this.authDetails));
    for (const singleButton of this.buttons) {
      this.buttonAuth[singleButton.name] = true;
    }
    /*
    if (this.buttonAuth.Authorize) {
      this.disableAuthorize = false;
    }
    */

    this.form = this.qcs.toFormGroup(this.formFields);
    this.querySubscriptions.push(
      this.serviceOrderDataService.isExpenseDataAvailable.subscribe({
        next: (isExpenseDataAvailable) => {
          if (isExpenseDataAvailable) {
            this.limitProcess();
          }
        },
      }),
    );
    this.querySubscriptions.push(
      this.serviceOrderDataService.isServicerInfoAvailable.subscribe({
        next: (isServicerInfoAvailable) => {
          this.limitProcess();
        },
      }),
    );
  }

  limitProcess() {
    const servicerInfo = this.serviceOrderDataService.serviceOrderDataModel.servicerInfo;
    if (servicerInfo) {
      const servicerNumber = servicerInfo.servicerNumber;
      let limitDetails = [];
      if (servicerNumber in this.servicerInfoCache) {
        limitDetails = this.servicerInfoCache[servicerNumber];
        this.compareLimit(limitDetails);
      } else {
        this.querySubscriptions.push(
          this.generatePreAuthService.getLimitDetails(servicerNumber).subscribe(
            ({ data, loading }) => {
              this.isLoading = false;
              limitDetails = data.getServicerPreAuthDetails;
              this.servicerInfoCache[servicerNumber] = data.getServicerPreAuthDetails;
              this.compareLimit(limitDetails);
            },
            (err) => {
              this.isLoading = false;
              window.alert('Limit Retrieval Failed. Please try again in a few minutes.');
            },
          ),
        );
      }
    }
  }

  compareLimit(limitDetails) {
    let limitStr = '';
    const preAuthRows = [];
    for (const singleLimit of limitDetails) {
      if (singleLimit.rateType === 'PreAuthorizationLimit') {
        preAuthRows.push(singleLimit);
        limitStr = singleLimit.limit;
      }
    }
    let bestLimitStr = '';
    if (preAuthRows.length > 1) {
      bestLimitStr = this.findBestMatch(preAuthRows);
    }
    let limitNumber = 0;
    if (bestLimitStr !== '') {
      limitStr = bestLimitStr;
    }
    if (limitStr !== '') {
      limitNumber = Number(limitStr.replace(/[^0-9\.]+/g, ''));
    }
    // Getting totalRequestedAmount from expenseCharges collection.
    const totalRequestedAmount = this.serviceOrderDataService.serviceOrderDataModel.expenseCharges
      .reduce((a, b) => a + (parseFloat(b.requestedAmount) || 0), 0)
      .toFixed(2);
    if (totalRequestedAmount && limitNumber < totalRequestedAmount) {
      this.disableRequest = false;
    } else if (totalRequestedAmount && limitNumber > totalRequestedAmount) {
      this.disableRequest = true;
    }
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick(event) {
    // ellipsis functionality goes here
  }

  onClickAdd($event) {
    const totalRequestedAmount = this.serviceOrderDataService.serviceOrderDataModel.expenseCharges
      .reduce((a, b) => a + (parseFloat(b.approvedAmount) || 0), 0)
      .toFixed(2);
    const newFormFields: FormField<string>[] = [];
    for (const singleFormField of this.formFields) {
      if (singleFormField.key === 'approvedAmount') {
        singleFormField.value = totalRequestedAmount.toString();
      }
      newFormFields.push(singleFormField);
    }
    this.formFields = newFormFields;
    this.form = this.qcs.toFormGroup(this.formFields);
    this.displayPreAuthDialog = true;
  }

  onCancel() {
    this.displayPreAuthDialog = false;
    this.isClicked=false;
  }

  onSubmit() {
    this.isClicked=true;
    const payload = this.form.getRawValue();
    const claimId = this.serviceOrderDataService.serviceConfig.searchParams.claimId;
    const approvedAmount = Number(payload.approvedAmount.replace(/[^0-9\.]+/g, ''));
    const servicerInfo = this.serviceOrderDataService.serviceOrderDataModel.servicerInfo;
    const servicerId = servicerInfo.servicerProfilesId;
    const payLoad = {
      claimNumber: claimId ? claimId.toString() : '',
      insertUserName: this.accountId,
      approvedAmount,
      servicerId,
      preAuthNotes: payload.preAuthNote,
      serviceOrderNumber:
        this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceOrderNumber,
    };
    this.querySubscriptions.push(
      this.generatePreAuthService.createUpdatePreAuthDetails(payLoad).subscribe((res: any) => {
        this.serviceOrderService.refreshClaimOnUpdate.next(true);
        const responseData = res.data.createOrUpdateExpensePreAuth;
        const newRow = {
          userName: this.currentUser,
          addedTimeStampFormatted: this.datePipe.transform(responseData.addedTimeStamp,'MM/dd/yyyy'),
          approvedAmountFormatted: `$${responseData.approvedAmount.toFixed(2).toString()}`,
          authorizationCode: responseData.authorizationCode,
          preAuthNotes: responseData.preAuthNotes,
          serviceOrderNumber:
            this.serviceOrderDataService.serviceOrderDataModel.claimData.serviceOrderNumber,
        };
        this.serviceOrderDataService.serviceOrderDataModel.preAuthInfo = newRow;
        if (
          this.serviceOrderDataService.serviceOrderDataModel.preAuthInfo &&
          this.serviceOrderDataService.validatorDataModel.preAuth !==
            this.serviceOrderDataService.serviceOrderDataModel.preAuthInfo.authorizationCode
        ) {
          this.serviceOrderDataService.validatorDataModel.preAuth = newRow.authorizationCode;
        }
        this.displayPreAuthDialog = false;
        this.isClicked=false;
        this.dataList.unshift(newRow);
      }),
    );
  }

  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }

  /* Find best match for Category Description, Subcategory Description, and Tier Description.
     Category and Subcategory can have an ALL value to match everything.
     Give rows priority for exact matches first.
  */
  findBestMatch(limitDetails) {
    const productInfoModel = this.serviceOrderDataService.serviceOrderDataModel.productInfoModel;
    const productInfoCatDesc = productInfoModel.categoryDescription;

    // Check exact match for Category.
    let matchingCat = [];
    for (const singleLimit of limitDetails) {
      if (
        productInfoCatDesc &&
        singleLimit.categoryDescription &&
        productInfoCatDesc.toUpperCase().trim() ===
          singleLimit.categoryDescription.toUpperCase().trim()
      ) {
        matchingCat.push(singleLimit);
      }
    }

    // If exact matches found, try to find matching sub cat and tier. Return if rows found.
    if (matchingCat.length > 0) {
      const subCatLimit = this.findSubCategoryMatches(matchingCat);
      if (subCatLimit !== '') {
        return subCatLimit;
      }
    }

    // No exact matches found, so get the categories that match ALL.
    matchingCat = [];
    for (const singleLimit of limitDetails) {
      if (
        singleLimit.categoryDescription &&
        singleLimit.categoryDescription.toUpperCase().trim() === 'ALL'
      ) {
        matchingCat.push(singleLimit);
      }
    }

    // If there are ALL rows found, then match the sub cat and tier for those. Return rows found.
    if (matchingCat.length > 0) {
      const subCatLimit = this.findSubCategoryMatches(matchingCat);
      if (subCatLimit !== '') {
        return subCatLimit;
      }
    }

    // If not rows found for exact match or ALL for any row, return no rows for last row found logic.
    return '';
  }

  findSubCategoryMatches(matchingCat) {
    const productInfoModel = this.serviceOrderDataService.serviceOrderDataModel.productInfoModel;
    const productInfoSubCatDesc = productInfoModel.subcategoryDescription;

    // Find the sub cat that match exactly.
    let matchingSubcat = [];
    for (const singleLimit of matchingCat) {
      if (
        productInfoSubCatDesc &&
        singleLimit.subcategoryDescription &&
        productInfoSubCatDesc.toUpperCase().trim() ===
          singleLimit.subcategoryDescription.toUpperCase().trim()
      ) {
        matchingSubcat.push(singleLimit);
      }
    }

    // If there were rows found, then match the tier (exact or ALL). Return row found
    if (matchingSubcat.length > 0) {
      const tierLimit = this.findTierMatches(matchingSubcat);
      if (tierLimit !== '') {
        return tierLimit;
      }
    }

    // No exact matches found. Find the ones that match ALL.
    matchingSubcat = [];
    for (const singleLimit of matchingCat) {
      if (
        singleLimit.subcategoryDescription &&
        singleLimit.subcategoryDescription.toUpperCase().trim() === 'ALL'
      ) {
        matchingSubcat.push(singleLimit);
      }
    }

    // If there are matches, need to find tier match to have good row.
    if (matchingSubcat.length > 0) {
      const tierLimit = this.findTierMatches(matchingSubcat);
      if (tierLimit !== '') {
        return tierLimit;
      }
    }

    // If no rows found, do not return any default rows.
    return '';
  }

  findTierMatches(matchingSubcat) {
    const productInfoModel = this.serviceOrderDataService.serviceOrderDataModel.productInfoModel;
    const productInfoTier = productInfoModel.tierDescription;

    // The tier field can be comma separated, so break it up and match any element.
    let matchingTier = [];
    for (const singleLimit of matchingSubcat) {
      if (singleLimit.description && productInfoTier) {
        const tierDescArray = singleLimit.description.split(',');
        const tierDescArrayUpper = tierDescArray.map((x) => {
          x.toUpperCase().trim();
        });
        if (tierDescArrayUpper.includes(productInfoTier.toUpperCase().trim())) {
          matchingTier.push(singleLimit);
        }
      }
    }

    // If there was one exact match, return that limit.
    if (matchingTier.length === 1) {
      return matchingTier[0].limit;
    }

    // If there are multiples that match (shouldn't), return last limit.
    if (matchingTier.length > 1) {
      return matchingTier[matchingTier.length - 1].limit;
    }

    // No exact matches were found. Match the ALL rows.
    matchingTier = [];
    for (const singleLimit of matchingSubcat) {
      if (singleLimit.description && singleLimit.description.toUpperCase().trim() === 'ALL') {
        matchingTier.push(singleLimit);
      }
    }

    // If one ALL row found, then return that limit.
    if (matchingTier.length === 1) {
      return matchingTier[0].limit;
    }

    // If multiple rows found (shouldn't), return the last one found.
    if (matchingTier.length > 1) {
      return matchingTier[matchingTier.length - 1].limit;
    }

    return '';
  }
}
