import { Injectable } from '@angular/core';
import { EmailService } from '../../../services/email.service';
import { MessageService } from 'primeng/api';
import { ServiceOrderDataService } from '../service-order-data.service';
import { NoteType } from '../../../shared/constants/enums';
import { AzureLoginService } from 'src/app/services/azure-login.service';
import { getMMDDYYYFormat } from '../../../shared/utilities/date-utilities';

const EMAIL_SENDS = {
  'PRE-APPROVED': 'TEMPLATE_ClaimStatusPreApproved_template1', // Service Provider Variables
  'PRE-APPROVED WITH MODIFICATIONS': 'TEMPLATE_ClaimStatusPreApproved_template1',
  'PENDING REVIEW': 'TEMPLATE_ClaimStatusPendingReview_template1',
  'SETTLEMENT PENDING': 'TEMPLATE_ClaimStatusSettlementPending_template1',
  'SHIPPING SUPPLIES SENT':'TEMPLATE_ClaimStatusShippingSupplies_template1'
};
@Injectable()
export class SendAutoEmailComponent {
  displayEmailModal: true;
  isLoading = true;
  validForm = true;

  constructor(
    private emailService: EmailService,
    private messageService: MessageService,
    private dataService: ServiceOrderDataService,
    private azureService: AzureLoginService,
  ) {}

  sendAutoEmail(updatedClaimStatus, currentClaimStatus) {
    let templateName = EMAIL_SENDS[updatedClaimStatus];

    // Dealer D-1264-ACE | ACE HARDWARE – HQ needs to use a different PENDING REVIEW template.

    if (updatedClaimStatus === 'PENDING REVIEW') {
      return;
    }
    if(updatedClaimStatus === 'PRE-APPROVED' || updatedClaimStatus === 'PRE-APPROVED WITH MODIFICATIONS'){
      this.dataService.isServiceTypeNotService.subscribe(x => {
        if(x){
          templateName = '';
        }
      })
    }
    const dealerNumber =
      this.dataService.serviceOrderDataModel.contractInfo &&
      this.dataService.serviceOrderDataModel.contractInfo.dealerGroupNumber
        ? this.dataService.serviceOrderDataModel.contractInfo.dealerGroupNumber
        : '';
    if (updatedClaimStatus === 'PENDING REVIEW' && dealerNumber === 'D-1264-ACE') {
      templateName = 'TEMPLATE_ClaimStatusPendingReviewGC_template1';
    }

    if (templateName in this.emailService.templateDetails) {
      let customerName = '';
      if (
        this.dataService.serviceOrderDataModel.claimData &&
        this.dataService.serviceOrderDataModel.claimData.contactInfo
      ) {
        const customerFirstName =
          this.dataService.serviceOrderDataModel.claimData.contactInfo.firstName;
        const customerLastName =
          this.dataService.serviceOrderDataModel.claimData.contactInfo.lastName;
        customerName = `${customerFirstName} ${customerLastName}`;
      }
      let emailAddress = '';
      const contactInfo = this.dataService.serviceOrderDataModel.contact;
      const customerInfo = this.dataService.serviceOrderDataModel.customerInfo;
      if (contactInfo && contactInfo.email) {
        emailAddress = contactInfo.email;
      } else if (customerInfo && customerInfo.customerEmail) {
        emailAddress = customerInfo.customerEmail;
      }
      const emailTemplateDetails = this.emailService.templateDetails[templateName];
      const templateVariables = emailTemplateDetails.emailTemplateVariables;
      const templateDescription = emailTemplateDetails.emailTemplateDescription;
      const variablesKeyValues = {};
      const variablesArray = templateVariables.split(',');
      let servicerNumber = 'N/A';
      let servicerName = 'N/A';
      if (this.dataService.serviceOrderDataModel.servicerInfo) {
        servicerNumber = this.dataService.serviceOrderDataModel.servicerInfo.contactInfo.businessPhone;
        servicerName = this.dataService.serviceOrderDataModel.servicerInfo.servicerName;
      }
      for (const variableName of variablesArray) {
        let variableValue = 'NOT_FOUND';
        if (variableName === 'service_provider_phone') {
          variableValue = servicerNumber;
        }
        else if (variableName === 'service_provider_name') {
          variableValue = servicerName;
        }
        else if (variableName === 'service_order_number') {
          if (this.dataService.serviceOrderDataModel.serviceOrderInfo) {
            variableValue = this.dataService.serviceOrderDataModel.serviceOrderInfo.serviceOrder;
          }
        }
        else if (variableName === 'customer_name') {
          variableValue = customerName;
        }
        else if (variableName === 'current_date') {
          variableValue = getMMDDYYYFormat(new Date().toDateString(), 'MM-DD-YYYY');
        }
        variablesKeyValues[variableName] = variableValue;
      }
      if (emailAddress === '') {
        // If there is no email address, skip.
        return;
      }
      const transactionDetails = this.addTransactionDetails(templateName, emailAddress);
      const request = {
        operation: 'send_template',
        data: {
          from: 'donotreply@newleafsc.net',
          receivers: [
            {
              Email: emailAddress,
              FirstName: 'FirstName',
              LastName: 'LastName',
            },
          ],
          variables: variablesKeyValues,
          template: templateName,
        },
        transactionDetails,
      };
      this.emailService.sendEmail(request).subscribe(
        (response) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: `Email sent successfully to ${emailAddress}. ${templateDescription}`,
          });
          const emailSentNote =
            'Email: Used ' +
            templateName +
            ' for ' +
            this.dataService.serviceOrderDataModel.claimData.serviceOrderNumber +
            ', Recipient: ' +
            emailAddress;
          this.dataService.notesDataModel.text = emailSentNote;
          this.dataService.notesDataModel.type = NoteType.SentEmail;
          this.dataService.newNote.next(true);
        },
        (err) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Email send Failed to ${emailAddress}. ${templateDescription}`,
          });
        },
      );
    }
  }

  addTransactionDetails(templateName, emailAddress) {
    return {
      ClaimId: this.dataService.serviceOrderDataModel.claimData.claimIdOriginal,
      ServiceOrderNumber: this.dataService.serviceOrderDataModel.claimData.serviceOrderNumber,
      EmailAddress: emailAddress,
      EmailTemplate: templateName,
      InsertNote: false,
      InsertUserName: this.azureService.fullName,
      InsertAzureId: this.azureService.accountId,
    };
  }
}
