import { Component, OnInit, OnDestroy } from '@angular/core';
import { ServiceOrderService } from '../../service-order.service';
import { ellipsisHeaderOptions } from 'src/app/modules/crm/shared/constants/ellipsis-options';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ServiceOrderDataService } from '../../service-order-data.service';
import { getMMDDYYYFormatDate } from 'src/app/modules/crm/shared/utilities/date-utilities';
import { copy } from 'src/app/modules/crm/shared/utilities/common-utilities';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'problem',
  templateUrl: './problem.template.html',
  styleUrls: ['problem.scss'],
})
export class ProblemComponent implements OnInit, OnDestroy {
  problemTableInfo: any[];
  problemInfo: any[];
  selectedItem: any;
  subClaimFlag = false;
  // Need a copy of the objects within the array so we do not update those by reference for other uses.
  ellipsisHeaderOptions: any[] = ellipsisHeaderOptions.map((object) => ({ ...object }));
  cols: any[];
  displayValidator = false;
  isEditEnable = true;
  querySubscriptions: Subscription[] = [];
  problemInfoInit = [
    { label: 'Failure Date', value: '' },
    { label: 'Report Date', value: '' },
    { label: 'Functional Status', value: '' },
    { label: 'Failure', value: '' },
    { label: 'TroubleShoot Fix', value: '' },
    { label: 'Failure Description', value: '' },
  ];

  constructor(
    private serviceOrderService: ServiceOrderService,
    private serviceOrderDataService: ServiceOrderDataService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    // this.serviceOrderService.getProblemTableDetails().subscribe(data => {
    //   this.problemTableInfo = data;
    // });
    this.serviceOrderDataService.saveDataComplete.subscribe((data) => {
      if (data) {
        this.updateProblemInfo();
      }
    });
    this.subClaimFlag = this.serviceOrderDataService.serviceConfig.searchParams.subClaimFlag;

    this.problemInfo = this.problemInfoInit;
    // this.serviceOrderService.getProblemInfo().subscribe(data => {
    //   this.problemInfo = data;
    // });
    this.cols = [
      { field: 'type', header: 'TYPE' },
      { field: 'note', header: 'NOTE' },
      { field: 'entered_time', header: 'ENTERED TIME' },
      { field: 'entered_by', header: 'ENTERED BY' },
    ];

    this.querySubscriptions.push(
      this.serviceOrderDataService.isClaimDataAvailable.subscribe({
        next: (isClaimDataAvailable) => {
          if (
            isClaimDataAvailable &&
            this.serviceOrderDataService.standardProblemDataModel &&
            this.serviceOrderDataService.standardProblemDataModel.failureDate
          ) {
            const { symptom } = this.serviceOrderDataService.standardProblemDataModel;
            if (symptom === '50 % Back' || symptom === '50% Back') {
              this.serviceOrderDataService.serviceConfig.isFPBClaim = true;
              this.serviceOrderDataService.serviceContractExpired.next(true);
            } else {
              this.serviceOrderDataService.serviceConfig.isFPBClaim = false;
              this.serviceOrderDataService.serviceContractExpired.next(false);
            }
            this.ellipsisHeaderOptions = this.ellipsisHeaderOptions.filter(
              (x) => x.label !== 'Add' && x.label !== 'Edit',
            );
            this.ellipsisHeaderOptions.push({
              label: 'Edit',
              value: 'edit',
              callback: 'onClickEdit',
            });
            this.transformProblemData();
          }
        },
      }),
    );

    // If the claim is in Submit for Payment, Approved for Payment or Approved Paid state,
    // No user can edit the expense charges details anymore(PD-907057)
    this.serviceOrderDataService.isServiceOrderEditEnable.subscribe((isEnable) => {
      this.isEditEnable = isEnable;
      this.ellipsisHeaderOptions
        .filter((c) => c.label === 'Edit' || c.label === 'Print' || c.label === 'Export')
        .forEach((x) => (x.disabled = !this.isEditEnable));
    });

    this.querySubscriptions.push(
      this.serviceOrderDataService.isServiceOrderInfoAvailable.subscribe({
        next: (isServiceOrderInfoAvailable) => {
          if (isServiceOrderInfoAvailable) {
            if (this.serviceOrderDataService.serviceOrderDataModel.claimData.disableClaimSave) {
              this.ellipsisHeaderOptions
                .filter((c) => c.label === 'Add' || c.label === 'Edit')
                .forEach((x) => (x.disabled = true));
            }
          }
        },
      }),
    );
  }

  updateProblemInfo() {
    this.transformProblemData();
  }

  patchDate(date) {
    let finalFormattedDispatchDate = null;
    if (date !== null) {
      const charCount = date.length;
      if(charCount){
      if (date && date.endsWith("Z")) {
        const slicingDate = date.slice(11, -1);
        if(slicingDate === "00:00:00.000"){
        finalFormattedDispatchDate = getMMDDYYYFormatDate(date)
        }
        else{
          finalFormattedDispatchDate = this.datePipe.transform(date, 'MM/dd/yyyy');
        }
      } else {
        finalFormattedDispatchDate = this.datePipe.transform(date, 'MM/dd/yyyy');
      }
    } else {
      finalFormattedDispatchDate = this.datePipe.transform(date, 'MM/dd/yyyy');
    }
  }
    return finalFormattedDispatchDate;
  }

  transformProblemData() {
    const probData = copy(this.serviceOrderDataService.standardProblemDataModel);
    const probDataExt = copy(this.serviceOrderDataService.standardProblemDataModelExtened);
    this.problemInfo = this.problemInfoInit.map((el) => {
      switch (el.label) {
        case 'Failure Date':
          // Do not add new Date() or else will convert to users timezone.
          el.value = this.patchDate(probData.failureDate);
          return el;
        case 'Report Date':
          // Do not add new Date() or else will convert to users timezone.
          el.value = this.patchDate(probData.reportedDate);
          return el;
        case 'Product DOP':
          el.value = probDataExt.productDop;
          return el;
        case 'Plan DOP':
          el.value = probDataExt.planDop;
          return el;
        case 'Mfg DOP':
          el.value = probDataExt.mfgDop;
          return el;
        case 'Functional Status':
          el.value = probData.functionalityStatus;
          return el;
        case 'Failure':
          el.value = probData.symptom;
          return el;
        case 'TroubleShoot Fix':
          el.value = probData.symptomFixNote;
          return el;
        case 'Failure Description':
          el.value = probData.failureDescription;
          return el;
        default:
          return el;
      }
    });
    if (this.subClaimFlag) {
      this.subClaimFlag = false;
    }
  }
  getMMDDYYYFormatDate(date){
    let finalFormattedDispatchDate = null;
    if (date !== null) {
      finalFormattedDispatchDate = date
        ? this.datePipe.transform(date, 'MM/dd/yyyy')
        : date.substring(0, 10);
    }
    return finalFormattedDispatchDate;
  }

  ellipsisClick(event, item, overlaypanel: OverlayPanel) {
    this.selectedItem = item;
    overlaypanel.toggle(event);
  }

  ellipsisOptionClick($event, overlaypanel: OverlayPanel) {
    // TODO:
    overlaypanel.hide();
  }

  onClickAdd() {
    this.displayValidator = true;
  }
  onClickEdit() {
    this.displayValidator = true;
    this.serviceOrderDataService.saveStandardFormData.next(false);
  }
  addItem($event) {
    // TODO:
  }
  ngOnDestroy() {
    this.querySubscriptions.forEach((subs) => subs.unsubscribe());
  }
}
